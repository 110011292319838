import { Create, SimpleForm, TextInput, required } from "react-admin";
import { apiCOD } from "../../../utils/utilsFetchBenefits";
import CustomFields from "../components/CustomFields";

export const Form = () => {
  return (
    <div style={{ display: "flex", gap: "16px", width: "100%" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <TextInput
          source='name'
          label='resources.benefit_types.fields.name'
          validate={[required()]}
        />
        {/* TODO ADD FIELDS */}
        <CustomFields source='fields' label='Campos extras' />
      </div>
    </div>
  );
};

export const BenefitTypeCreate = ({ ...props }) => {
  const transform = (values) => {
    let result = { ...values };
    result.company = apiCOD;
    return result;
  };
  return (
    <Create title=' ' redirect='list' transform={transform} {...props}>
      <SimpleForm>
        <Form />
      </SimpleForm>
    </Create>
  );
};
