import {
  Create,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  AutocompleteInput,
  useQueryWithStore,
  useRecordContext,
} from "react-admin";
import { apiCOD } from "../../../utils/utilsFetchBenefits";
import { useFormState, useField } from "react-final-form";
import { Box, Typography } from "@material-ui/core";
import { useEffect } from "react";

export const Form = () => {
  const record = useRecordContext();
  const { values } = useFormState();
  const { input: input_workshop_id } = useField("workshop_id");

  useEffect(() => {
    if (record?.workshopdate) {
      input_workshop_id.onChange(record?.workshopdate?.workshop_id);
    }
  }, [record]);
  const details = values?.details ? JSON.parse(values?.details) : null;
  const { data: workshops } = useQueryWithStore({
    type: "getList",
    resource: "workshop",
    payload: {
      filter: {
        withTrashed: true,
      },
      sort: { field: "name", order: "ASC" },
      pagination: { page: 1, perPage: 10000 },
    },
  });

  const getOptions = (value, options) => {
    return options?.find((i) => i.id == value)?.workshopdates;
  };
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h6">Suscripción a taller</Typography>
      <TextInput
        source="user_id"
        label="resources.subscription_workshopdate.fields.user_id"
        validate={[required()]}
        style={{ maxWidth: "300px" }}
      />
      <TextInput
        source="email"
        label="resources.subscription_workshopdate.fields.email"
        validate={[required()]}
        style={{ width: "450px" }}
        multiline
      />
      <TextInput
        source="name"
        label="resources.subscription_workshopdate.fields.name"
        validate={[required()]}
        style={{ width: "450px" }}
        multiline
      />
      <TextInput
        source="lastname"
        label="resources.subscription_workshopdate.fields.lastname"
        validate={[required()]}
        style={{ width: "450px" }}
        multiline
      />
      {workshops ? (
        <AutocompleteInput
          source="workshop_id"
          choices={workshops}
          optionText={"name"}
          filter={(values) => values?.workshopdates?.lenght > 0}
        />
      ) : null}
      {values?.workshop_id && workshops ? (
        <AutocompleteInput
          source="workshopdate_id"
          optionValue="id"
          optionText="date"
          choices={getOptions(values?.workshop_id, workshops)}
        ></AutocompleteInput>
      ) : null}

      <Box>
        <SelectInput
          source="status"
          label="resources.subscription_workshopdate.fields.status"
          validate={[required()]}
          choices={[
            { id: "activo", name: "Activo" },
            { id: "cancelado", name: "Cancelado" },
          ]}
        />
      </Box>

      {details ? (
        <>
          <Typography>Detalles asistencia</Typography>
          <ul>
            {details?.map((item, key) => (
              <li key={key} style={{ margin: "8px 0" }}>
                <ul style={{ listStyle: "none", paddingLeft: 0 }}>
                  <li>
                    <strong>Nombre:</strong> {item?.name}
                  </li>
                  <li>
                    <strong>Apellido:</strong> {item?.lastname}
                  </li>
                  <li>
                    <strong>DNI:</strong> {item?.dni}
                  </li>
                  {item?.phone ? <li>
                    <strong>Telefono:</strong> {item.phone}
                  </li> : null}
                </ul>
              </li>
            ))}
          </ul>
        </>
      ) : null}
    </Box>
  );
};

export const SubscriptionWorkshopdateCreate = ({ ...props }) => {
  const transform = (values) => {
    let copy = { ...values };
    copy.company = apiCOD;
    return copy;
  };
  return (
    <Create title=" " transform={transform} redirect="list" {...props}>
      <SimpleForm>
        <Form />
      </SimpleForm>
    </Create>
  );
};
