import { useRecordContext } from "ra-core";
import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrayInputSimple from "./ArrayInputSimple";
import ObjectCreate from "./ObjectCreate";
import { useField} from "react-final-form";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    minWidth: "300px",
  },
}));

const components = [
  { name: "Texto", value: "text" },
  { name: "Número", value: "number" },
  { name: "Opciones Simple", value: "options" },
  { name: "Objeto", value: "object" },
  { name: "Arreglo de objetos", value: "array-object" },
];

export default function CustomFields({ source, label }) {
  const record = useRecordContext();
  const classes = useStyles();
  const [inputValue, setInputValue] = useState({
    name: "",
    component: "",
    value: "",
    options: "",
  });
 
  const [array, setArray] = useState([]);
 const { input:input_form} = useField(source);

  useEffect(() => {
    if (record && record[`${source}`]) {
      setArray(record[`${source}`]);
    }
  }, [record]);

  useEffect(() => {
    input_form.onChange(array);
  }, [array]);

  const handleAdd = () => {
    if (inputValue) {
      const newArray =[...array, inputValue];
      setArray(newArray);
      setInputValue({
        name: "",
        component: "",
        value: "",
        options: "",
      });
    }
  };

  const handleDelete = (index) => {
    const newArray = array.filter((_, i) => i !== index);
    setArray(newArray);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h6" style={{ marginBottom: "16px" }}>
        {label}
      </Typography>
      <Box
        display="flex"
        style={{ gap: "16px", alignItems: "center", justifyContent: "center" }}
      >
        <TextField
          label="Nombre"
          value={inputValue.name}
          onChange={(e) =>
            setInputValue({ ...inputValue, name: e.target.value })
          }
        />
        <FormControl className={classes.formControl}>
          <InputLabel id="select-component">Componente</InputLabel>
          <Select
            labelId="select-component"
            value={inputValue.component}
            onChange={(e) =>
              setInputValue({ ...inputValue, component: e.target.value })
            }
          >
            {components?.map((i, key) => (
              <MenuItem value={i.value} key={key}>
                {i.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {inputValue.component == "options" ? (
          <ArrayInputSimple
            data={inputValue.options}
            onChange={(values) =>
              setInputValue({ ...inputValue, options: values })
            }
          />
        ) : null}
        {inputValue.component == "object" ? (
          <ObjectCreate
            data={inputValue.structure}
            onChange={(values) =>{
              setInputValue({ ...inputValue, value: values })
            }
            }
          />
        ) : null}
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<AddIcon />}
            onClick={handleAdd}
          >
            Agregar
          </Button>
        </Box>
      </Box>

      <List>
        {array?.map((item, index) => (
          <Box key={index}>
            <ListItem>
              <ListItemText primary={item.name} secondary={item.component} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDelete(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </Box>
        ))}
      </List>
    </div>
  );
}
