import {
  Datagrid,
  DateInput,
  Filter,
  List,
  NumberField,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";
import { ActionsCreate } from "../../../components/Toolbar/ActionsCreate";
import { checkPermissions } from "../../../utils/validations";

const CHOICES = [
  { id: "draft", name: "Borrador" },
  { id: "active", name: "Activo" },
  // { id: "disabled", name: "Deshabilitado" },
  { id: "finished", name: "Finalizado" },
  { id: "cancelled", name: "Cancelado" },
  { id: "soldout", name: "Agotado" },
  { id: "last_tickets", name: "Últimos tickets"}
];

const CustomFilters = (props) => (
  <Filter {...props}>
    <TextInput source="search" label="ra.action.search" alwaysOn />
    <SelectInput
      source="status"
      choices={CHOICES}
      label="Estado"
    />
    <DateInput source="date" label="Fecha" />
  </Filter>
);

export const WorkshopList = ({ ...props }) => {
  const canEdit = checkPermissions(props?.permissions, "edit-workshop");

  return (
    <List
      sort={{ field: "created_at", order: "DESC" }}
      bulkActionButtons={false}
      title=" "
      filters={<CustomFilters />}
      actions={<ActionsCreate />}
      {...props}
    >
      <Datagrid
        rowClick={canEdit ? "edit" : "show"}
        actions={
          <ActionsCreate
            createButton={checkPermissions(
              props?.permissions,
              "create-workshop"
            )}
          />
        }
        {...props}
      >
        <TextField source="name" />
        <NumberField source="subscriptions_count" label={"Suscripciones a taller"} />
        <SelectField source="status" label={"Estado"} choices={CHOICES}/>
        <TextField source="categories[0].name" label={"Categoría Principal"} />
      </Datagrid>
    </List>
  );
};
