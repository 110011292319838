import * as React from "react";
import { Edit, SimpleForm, TextInput, BooleanInput, Toolbar, SaveButton, useRecordContext, DateInput, SelectInput, ReferenceInput, AutocompleteInput } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import provinces from "./provinces.json";
import countries from "./countries-iso.json";
import Aside from "./aside";

export const interestsOptions = [
    { value: 1, label: "Derechos Humanos", icon: "HumanRightsIcon" },
    { value: 2, label: "Deportes", icon: "SportsIcon" },
    { value: 3, label: "Política Argentina", icon: "PolicyIcon" },
    { value: 4, label: "El Mundo", icon: "WorldIcon" },
    { value: 5, label: "Espectáculos", icon: "ShowIcon" },
    { value: 6, label: "Cultura", icon: "CultureIcon" },
    { value: 7, label: "Literatura", icon: "LiteratureIcon" },
    { value: 8, label: "Género y diversidad", icon: "GenderIcon", },
    { value: 9, label: "Historia", icon: "HistoryIcon" },
    { value: 10, label: "Latinoamérica", icon: "LatinAmericaIcon" },
    { value: 11, label: "Ciencia y tecnología", icon: "CienceIcon" },
];
export const genderOptions = [
    { value: "female", label: "Femenino" },
    { value: "male", label: "Masculino" },
    { value: "non-binary", label: "No binarie" },
    { value: "other", label: "Otro" },
];
const Newsletters = () => {
    const record = useRecordContext();
    const newsletters = record?.newsletters;
    if (!newsletters || newsletters?.length == 0) return null;
    return (
        <>
            <Typography variant="h6">Newsletters</Typography>
            <ul>
                {newsletters?.map((item, key) => (<li key={key}>{item}</li>))}
            </ul>
        </>
    )
}

const Intereses = () => {
    const record = useRecordContext();
    const interests = record?.interests;
    if (!interests || interests?.length == 0) return null;
    return (
        <>
            <Typography variant="h6">Intereses</Typography>
            <ul>
                {interests?.map((item, key) => (<li key={key}>{interestsOptions.find(i => i?.value == item)?.label || ""}</li>))}
            </ul>
        </>
    )
}

const useStyles = makeStyles({
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
    }
});
const EditToolbar = (props) => {
    const record = useRecordContext();
    const deepCompare = (obj1, obj2) => {
        if (Array.isArray(obj1) && Array.isArray(obj2)) {
            if (obj1.length !== obj2.length) {
                return false;
            }
            for (let i = 0; i < obj1.length; i++) {
                if (obj1[i] !== obj2[i]) {
                    return false;
                }
            }
            return true;
        } else if (typeof obj1 === 'object' && typeof obj2 === 'object') {
            for (const key in obj1) {
                if (!obj2.hasOwnProperty(key) || !deepCompare(obj1[key], obj2[key])) {
                    return false;
                }
            }
            for (const key in obj2) {
                if (!obj1.hasOwnProperty(key)) {
                    return false;
                }
            }
            return true;
        } else {
            return obj1 === obj2;
        }
    };

    const transform = (values) => {
        const modifiedFields = {};
        for (const key in values) {
            if (values.hasOwnProperty(key) && !deepCompare(values[key], props.record[key])) {
                modifiedFields[key] = values[key];
            }
        }
        delete modifiedFields.payments;
        return modifiedFields;
    }
    return (
        <Toolbar classes={useStyles()} {...props}>
            <SaveButton transform={transform} />
        </Toolbar>
    );
};

export const RegisteredUsersEdit = (props) => {
    return (
        <Edit title=" " {...props} aside={<Aside />}>
            <SimpleForm toolbar={<EditToolbar />}  >
                <BooleanInput source="staff" />
                <BooleanInput source="contributor" label="Contribuidor" />
                <TextInput source="first_name" label="Nombre" />
                <TextInput source="last_name" label="Apellido" />
                <TextInput source="plan" label="Plan" />
                <TextInput source="plan_price" label="Precio Plan" />
                <TextInput source="email" />
                <TextInput source="display_name" label="Nombre de usuario" />
                <ReferenceInput source="artic_plan" label="Plan artic" reference="plans" optionValue="id" optionText="name" >
                    <AutocompleteInput source="artic_plan" optionValue="id" optionText="name"/>
                </ReferenceInput>
                <SelectInput source="country" choices={countries} optionValue="value" optionText="label" label="Pais" allowEmpty emptyValue={null} translateChoice={false} resettable />
                <SelectInput source="state" choices={provinces} optionValue="value" optionText="label" label="Provincia" allowEmpty emptyValue={null} translateChoice={false} resettable />
                <SelectInput source="gender" choices={genderOptions} label="Genero" optionValue="value" optionText="label" allowEmpty emptyValue={null} translateChoice={false} resettable />
                <DateInput source="date_of_birth" />
                <Newsletters label="Newsletters" />
                <Intereses />
            </SimpleForm>
        </Edit>
    )
};
