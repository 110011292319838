import { List, ListItem, ListItemText,Card ,Typography} from "@material-ui/core";

export const ShowList = ({ data,title ,primary, secondary, secondaryText= "cantidad: " }) => {
  return (
    <Card>
      <Typography style={{ padding: "16px 16px 0 16px", color:"rgba(0, 0, 0, 0.54)" }}>{title}</Typography>
    <List>
      {data?.map((i, key) => (
        <ListItem key={key}>
          <ListItemText primary={i[primary]}  secondary={secondary ? `${secondaryText} ${i[secondary]}` : null}/>
        </ListItem>
      ))}
    </List>
    </Card>
  );
};
