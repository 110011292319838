import Resources from "Static/Resources";
import medio from "Static/data/medio.json";
import polyglotI18nProvider from "ra-i18n-polyglot";
import * as React from "react";
import { Admin } from "react-admin";
import articLogo from "../src/assets/img/artic-logo.svg";
import CustomLayout from "./components/layout/Layout";
import { messages as englishMessages } from "./language/language-en";
import { messages as spanishMessages } from "./language/language-es";
import authProvider from "./providers/authProvider";
import superDataProvider from "./providers/superDataProvider";
import customRoutes from "./routes/customRoutes";
import LoginWithTheme from "./views/auth/Login";

const messages = {
  en: englishMessages,
  es: spanishMessages,
};

const default_language = medio?.default_language
  ? `${medio?.default_language}`
  : "es";

const i18nProvider = polyglotI18nProvider(
  (locale) => messages[locale],
  default_language
);

const App = () => {
  const numVersion = "1.24.8";

  React.useEffect(() => {
    const text =
      default_language == "en" ? "Content Manager" : "Gestor de Contenidos";
    document.title = `${text} | ${medio.nombre}`;
  }, []);

  React.useEffect(() => {
    window.addEventListener("DOMSubtreeModified", insertVersion);

    return () => {
      window.removeEventListener("DOMSubtreeModified", insertVersion);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const insertVersion = React.useCallback((event) => {
    const main = document.querySelector("#main-content");
    const versionId = document.querySelector("#versionId");
    if (main && !versionId) {
      const version = document.createElement("div");
      const text =
        default_language == "en"
          ? "All rights reserved"
          : "Todos los derechos reservados ";
      version.id = "versionId";
      version.style.display = "flex";
      version.style.alignItems = "center";
      version.style.justifyContent = "center";
      version.style.position = "absolute";
      version.style.bottom = "0";
      version.style.right = "0";
      version.style.padding = "6px";
      version.style.paddingRight = "8px";
      version.style.fontSize = "11px";

      version.innerHTML = `<img src=${articLogo} alt="artic logo" width=${45}/>
      <span>${new Date().getFullYear()}™ - ${text} - Versión ${numVersion} </span>`;

      main.appendChild(version);
    }
  }, []);

  return (
    <>
      <Admin
        title={`Gestor de Contenidos | ${medio.nombre}`}
        loginPage={LoginWithTheme}
        layout={CustomLayout}
        authProvider={authProvider}
        dataProvider={superDataProvider}
        i18nProvider={i18nProvider}
        customRoutes={customRoutes}
      >
        {(permissions) => Resources(permissions)}
      </Admin>
    </>
  );
};

export default App;
