import {
  Create,
  SimpleForm,
  TextInput,
  required,
  useTranslate,
  useRecordContext,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import { apiCOD } from "../../../utils/utilsFetchBenefits";
import { makeStyles } from "@material-ui/styles";
import medio from "Static/data/medio.json";

const useStyles = makeStyles({
  file: {
    width: "500px",
  },
});

export const Form = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const record = useRecordContext();

  return (
    <div style={{ display: "flex", gap: "16px", width: "100%" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <TextInput
          source='name'
          label={medio?.translations?.province || 'resources.provinces.fields.name'}
          validate={[required()]}
        />
      </div>
    </div>
  );
};

export const ProvincesCreate = ({ ...props }) => {
  const transform = (values) => {
    let result = { ...values };
    result.company = apiCOD;
    return result;
  };

  return (
    <Create title=' ' redirect='list' transform={transform} {...props}>
      <SimpleForm>
        <Form />
      </SimpleForm>
    </Create>
  );
};
