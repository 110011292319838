import * as React from 'react';
import {
    Create,
    DateInput,
    SimpleForm,
    TextInput,
    PasswordInput,
    required,
    email,
    minLength,
    NumberInput
} from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import { AvatarInput } from './AvatarInput';
import { useTranslate } from "ra-core";

export const validatePasswords = ({
    password,
    password_confirmation,
}) => {
    const errors = {};

    if (password && password !== password_confirmation) {
        errors.password_confirmation = [
            'resources.users.errors.password_mismatch',
        ];
    }

    return errors;
};

export const MemberCreate = ({ permissions, ...props }) => {
    const translate = useTranslate();
    return (
        <Create {...props} title=" " resource="subscribers">
            <SimpleForm validate={validatePasswords}>
                <Box width={{ xs: '100%!important', md: '60%!important' }}>
                    <Box display={{ md: 'block', lg: 'flex' }}>
                        <Box flex={3} mr={{ md: 0, lg: '1em' }}>
                            <Typography variant="h6" gutterBottom>
                                {translate("components.add_user")}
                            </Typography>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} style={{ alignSelf: "center" }}>
                                    <AvatarInput resource="subscribers" fullWidth />
                                </Box>
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box
                                    flex={1}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                >
                                    <TextInput
                                        source="first_name"
                                        resource="subscribers"
                                        validate={requiredValidate}
                                        fullWidth
                                    />
                                </Box>
                                <Box
                                    flex={1}
                                    ml={{ xs: 0, sm: '0.5em' }}
                                >
                                    <TextInput
                                        source="last_name"
                                        resource="subscribers"
                                        validate={requiredValidate}
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1}>
                                    <TextInput
                                        type="email"
                                        source="email"
                                        resource="subscribers"
                                        validate={[email(), required()]}
                                        fullWidth
                                    />
                                </Box>
                                <Box flex={1}></Box>
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <DateInput
                                    source="birthday"
                                    resource="subscribers"
                                />
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <TextInput
                                    source="dni"
                                    resource="subscribers"
                                />
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <TextInput
                                        source="phone_feature"
                                        resource="subscribers"
                                    />
                                </Box>
                                <Box flex={3}>
                                    <TextInput
                                        source="phone"
                                        resource="subscribers"
                                    />
                                </Box>
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <TextInput
                                    source="dealer"
                                    resource="subscribers"
                                />
                            </Box>
                            <Box mt="1em" />

                            <Typography variant="h6" gutterBottom>
                            {translate("components.address")}
                            </Typography>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box
                                    flex={1}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                >
                                    <TextInput
                                        source="country"
                                        resource="subscribers"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                                <Box
                                    flex={1}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                >
                                    <TextInput
                                        source="province"
                                        resource="subscribers"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                                <Box
                                    flex={1}
                                >
                                    <TextInput
                                        source="city"
                                        resource="subscribers"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box
                                    flex={2}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                >
                                    <TextInput
                                        source="address"
                                        resource="subscribers"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                                <Box
                                    flex={1}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                >
                                    <NumberInput
                                        source="addr_floor"
                                        resource="subscribers"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                                <Box
                                    flex={1}
                                >
                                    <TextInput
                                        source="addr_door"
                                        resource="subscribers"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                            </Box>

                            <Box mt="1em" />

                            <Typography variant="h6" gutterBottom>
                                {translate("ra.auth.password")}
                            </Typography>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box
                                    flex={1}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                >
                                    <PasswordInput
                                        source="password"
                                        resource="subscribers"
                                        validate={[required(), minLength(6)]}
                                        fullWidth
                                    />
                                </Box>
                                <Box
                                    flex={1}
                                    ml={{ xs: 0, sm: '0.5em' }}
                                >
                                    <PasswordInput
                                        source="password_confirmation"
                                        resource="subscribers"
                                        validate={[required(), minLength(6)]}
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </SimpleForm>
        </Create >
    );
};

const requiredValidate = [required()];