import * as React from "react";
import {
  Datagrid,
  EditButton,
  EmailField,
  Filter,
  List,
  TextField,
  TextInput,
  NumberField,
  ReferenceInput,
  SelectInput,
  BooleanInput,
} from "react-admin";
import RolField from "./RolField";
import FullNameField from "./FullNameField";
import { ActionsCreate } from "../../components/Toolbar/ActionsCreate";
import medio from "Static/data/medio.json";
import { checkPermissions } from "../../utils/validations";
import { RestoreButton } from "../../components/RestoreButton";
import { useTranslate} from "ra-core";
const JournalistFilter = (props) => {
  const translate= useTranslate();
  return(
  <Filter {...props}>
    <TextInput source="search" label="ra.action.search" alwaysOn />
    <ReferenceInput
      label={translate("resources.journalists.fields.roles")}
      source="roles"
      reference="roles"
      sort={{ field: "title", order: "ASC" }}
      perPage={1000}
      allowEmpty
    >
      <SelectInput optionText="title" />
    </ReferenceInput>
    <BooleanInput source="deleted_at" label={translate("components.deleted")} />
  </Filter>
  )
}

const Actions = ({ record }) => {
  if (record?.deleted_at !== null) return null;
  return <EditButton record={record} />
}

export const JournalistList = ({ permissions, ...props }) => {
  const translate = useTranslate();
  const canEdit = checkPermissions(permissions, "edit-journalists");
  const canCreate = checkPermissions(permissions, "create-journalists");
  return (
    <List
      filters={<JournalistFilter />}
      {...props}
      sort={{ field: "first_name", order: "ASC" }}
      title=" "
      actions={
        <ActionsCreate label={`${translate("resources.journalists.new")}`} createButton={canCreate}/>
      }
    >
      <Datagrid rowClick={undefined}>
        <TextField source="id" />
        <FullNameField sortBy="users.first_name" source="username" />
        <EmailField source="email" />
        <RolField source="roles" sortable={false} label="resources.journalists.fields.roles"/>
        {!medio?.only_club ? <NumberField source="notes_count" label="resources.authors.fields.notes_count" /> : null}
        {canEdit &&
          <Actions {...props} />}
        {canEdit ?
          <RestoreButton {...props} />
          : null}
      </Datagrid>
    </List>
  );
};
