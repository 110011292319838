import * as React from "react";
import { List, Datagrid, TextField, ImageField } from "react-admin";
import { checkPermissions } from "../../../utils/validations";
import { makeStyles } from "@material-ui/styles";
import { ActionsCreate } from "../../../components/Toolbar/ActionsCreate";

const useStyles = makeStyles({
  img: {
    width: "60px!important",
    height: "60px!important",
    "& img": {
      maxWidth: "150px",
      maxHeight: "60px!important",
      padding: 0,
      margin: 0,
    },
  },
  importantField: {
    fontWeight: 600,
  },
});

export const AuthorsList = ({ ...props }) => {
  const classes = useStyles();
  const canEdit = checkPermissions(props?.permissions, "edit-benefit-authors");
  const canCreate = checkPermissions(props?.permissions, "create-benefit-authors");
  return (
    <List
      {...props}
      title=' '
      bulkActionButtons={false}
      actions={canEdit ? <ActionsCreate createButton={canCreate}/> : ""}
    >
      <Datagrid rowClick={() => (canEdit ? "edit" : "")}>
        <ImageField label={"Imagen"} source='image' className={classes.img} />
        <TextField
          label={"Nombre de autor"}
          source='name'
          className={classes.importantField}
        />
        <TextField source='description' />
      </Datagrid>
    </List>
  );
};
