import dataProviderApi from './dataProviderApi'; //handle artic dataProvider
import dataProviderBenefits from './dataProviderBenefits';// handle benefit dataProvider
import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
  GET_MANY,
  GET_MANY_REFERENCE,
} from 'react-admin';

const isBenefitResource = resource => {
  const benefitResource = [
    'benefits',
    'benefit_assets',
    'benefit_types',
    'benefit_categories',
    'business',
    'provinces',
    'cities',
    'locations',
    'workshop',
    'subscription_workshopdate',
    'benefit_authors',
    'metric_benefits'
  ];
  return benefitResource.some(ele => ele === resource);
};


const mappingType = {
  [GET_LIST]: 'getList',
  [GET_ONE]: 'getOne',
  [GET_MANY]: 'getMany',
  [GET_MANY_REFERENCE]: 'getManyReference',
  [CREATE]: 'create',
  [UPDATE]: 'update',
  [UPDATE_MANY]: 'updateMany',
  [DELETE]: 'delete',
  [DELETE_MANY]: 'deleteMany',
  'locked': 'locked',
  'unlocked': 'unlocked',
  'getAbilities': 'getAbilities',
};

const superDataProvider = (type, resource, params) => {
  if (isBenefitResource(resource)) {
    return dataProviderBenefits[mappingType[type]](resource, params);
  }
  return dataProviderApi[mappingType[type]](resource, params);
};

export default superDataProvider;