import * as React from "react";
import { Card, CardHeader, CardContent } from "@material-ui/core";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
} from "recharts";

const PieChartCard = ({ data = [], title }) => {
  if (!data.length > 0) return false;

  // Data tiene que ser un array de objetos con 2 keys cada uno, correspondientes a los ejes X e Y del gráfico
  const dataKeys = Object.keys(data[0]);
  const COLORS = ["#321EE3", "#2685ED", "#2E5DD6", "#2638ED", "#24A3E3"];

  const renderLegend = () => {
    const total = data.reduce((acc, cur) => acc + cur.count, 0);
    return (
      <p
        style={{
          display: "flex",
          justifyContent: "flex-end",
          fontSize: "1.2rem",
          marginBottom: 0,
        }}
      >
        Total: <strong style={{ marginLeft: "5px" }}> {total}</strong>
      </p>
    );
  };

  const renderCustomizedLabel = ({
    percent,
    index,
  }) => {
    return data[index][
      dataKeys[0]
    ] + " " + (percent * 100).toFixed(0) + "%";
  };

  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <div style={{ width: "100%", height: 394 }}>
          <ResponsiveContainer>
            <PieChart width={"100%"} height={394}>
              {/* <Legend content={renderLegend} /> */}
              <Pie
                nameKey={dataKeys[0]}
                dataKey={dataKeys[1]}
                data={data}
                innerRadius={40}
                outerRadius={140}
                // labelLine={false}
                paddingAngle={1}
                label={renderCustomizedLabel}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`pie-cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
};

export default PieChartCard;
