import * as React from "react";
import { List, Datagrid, TextField } from "react-admin";
import { checkPermissions } from "../../../utils/validations";
import { ActionsCreate } from "../../../components/Toolbar/ActionsCreate";
import medio from "Static/data/medio.json";

export const CitiesList = ({ ...props }) => {
  const canEdit = checkPermissions(props?.permissions, "edit-cities");
  const canCreate = checkPermissions(props?.permissions, "create-cities");
  return (
    <List
      {...props}
      title=' '
      bulkActionButtons={false}
      actions={<ActionsCreate createButton={canCreate} />}
    >
      <Datagrid rowClick={() => (canEdit ? "edit" : "")}>
        <TextField source='name' label={medio?.translations?.city || "resources.cities.fields.name"} />
        <TextField source='province.name' label={medio?.translations?.province || "resources.cities.fields.parent_id"} />
      </Datagrid>
    </List>
  );
};
