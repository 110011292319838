const makeDate = (datetime) => {
    const dateParts = datetime.substring(0, 10).split("-");
    return new Date(dateParts[0], dateParts[1] - 1, dateParts[2].substr(0, 2));
}

export const userMetrics = (data, user = null) => {

    // Contadores de caracteres
    let charCountFree = 0;
    let charCountPremium = 0;
    let charCountPaper = 0;

    // Cantidad de notas por tipo
    let notesTotal = 0;
    let notesFree = 0;
    let notesPremium = 0;
    let notesPaper = 0;
    let notesFreePremium = 0;
    let notesUnknown = 0;
    

    // Contenidos por usuario
    const notesPerUser = [];

    if (data.length > 0) {

        // ========================================================================
        // Total de notas por dia
        // ========================================================================
        const notesCount = data.reduce((outData, item) => {
            let lastId = outData.length > 0 ? outData.length - 1 : 0;

            // Verifica si tiene filtrado algún usuario
            if (!user || user === item.user) {

                notesTotal++;

                // Incrementa los contadores de caracteres
                charCountFree = charCountFree + item.free_count;
                charCountPremium = charCountPremium + item.premium_count;
                charCountPaper = charCountPaper + item.paper_count;

                // Incrementa contadores de notas
                notesFree = notesFree + (item.free_count > 0 && item.premium_count === 0 ? 1 : 0);
                notesPremium = notesPremium + (item.premium_count > 0 && item.free_count === 0 ? 1 : 0);
                notesPaper = notesPaper + (item.paper_count > 0 ? 1 : 0);
                notesFreePremium = notesFreePremium + (item.free_count > 0 && item.premium_count > 0 ? 1 : 0);
                notesUnknown = notesUnknown + (item.free_count === 0 && item.premium_count === 0 ? 1 : 0);

                // Calcula fecha actual
                const currentDate = makeDate(item.date);

                // Inicializa el array en caso de no existir
                if (!outData.length > 0) outData.push({ date: currentDate, Notas: 0 })

                // Verifica si es mayor a la última fecha
                // Si lo es, crea una nueva entrada
                if (currentDate > outData[lastId].date) {
                    //Incrementa el array
                    lastId++;
                    outData.push({ date: currentDate, Notas: 0 })
                }

                // Contador de notas
                outData[lastId].Notas++;

                // ========================================================================
                // Notas por usuario
                // ========================================================================

                // Inicializa el array en caso de primer ingreso
                if (!notesPerUser.length > 0) notesPerUser.push({ user: item.user, count: 0 })

                // Busca el usuario en el array 
                const userId = notesPerUser.findIndex(row => row.user === item.user);

                // Incrementa o lo crea según sea necesario
                if (userId >= 0) {
                    notesPerUser[userId] = {
                        user: item.user,
                        count: notesPerUser[userId].count + 1
                    }
                } else {
                    notesPerUser.push({
                        user: item.user,
                        count: 1
                    })
                }
            }


            // -----
            // Fin del reducer
            return outData;

        }, []);

        // ========================================================================
        // Métricas de un solo valor
        // ========================================================================

        // Calculo de promedios de caracteres
        const charAvFree = (charCountFree / notesTotal).toFixed(0);
        const charAvPremium = (charCountPremium / notesTotal).toFixed(0);
        const charAvPaper = (charCountPaper / notesTotal).toFixed(0);

        return {
            notesPerDay: notesCount,
            notesTotal: notesTotal,
            notesFree: notesFree,
            notesPremium: notesPremium,
            notesFreePremium: notesFreePremium,
            notesUnknown: notesUnknown,
            charAvFree: charAvFree,
            charAvPremium: charAvPremium,
            charAvPaper: charAvPaper,
            notesPerUser: notesPerUser,
            notesPaper: notesPaper
        };
    }

    return null;
}

export const subscriptionMetrics = (data) => {

    if (data) {
        // ========================================================================
        // Contenidos previos a suscripcion
        // ========================================================================
        const subByUrl = data.sub_url.reduce((outData, item) => {
            let lastId = outData.length > 0 ? outData.length - 1 : 0;

            // Inicializa el array en caso de no existir
            if (!outData.length > 0) outData.push({ id: 0, url: item.meta, bounced: 0, unfulfilled: 0, fulfilled: 0, successful: 0 })

            // Verifica si es la misma url, o si cambio y debe agregarse la entrada
            if (item.meta !== outData[lastId].url) {
                outData.push({ id: lastId + 1, url: item.meta, bounced: 0, unfulfilled: 0, fulfilled: 0, successful: 0 });
                lastId++;
            }

            // Incrementa el valor segun status
            outData[lastId][item.status]++;

            // -----
            // Fin del reducer
            return outData;

        }, []);

        // ========================================================================
        // Subscriptores por plan
        // ========================================================================
        const subByPlan = data.subscriptions.reduce((outData, item) => {

            if (typeof outData.plans_ids[item.plan_id] === 'undefined') {
                outData.plans_ids[item.plan_id] = outData.acc.length;
                outData.acc.push({ plan: item.plan.name, count: 0 });
            }

            // Incrementa los contadores correspondientes
            if (item.status === 'active') {
                outData.acc[outData.plans_ids[item.plan_id]].count++;
                if (item.plan?.price > 0) {
                    outData.total++;
                }
            }

            // -----
            // Fin del reducer
            return outData;

        }, { plans_ids: {}, acc: [], total: 0 });

        // Churn Rate
        const total = subByPlan.total - data.signups;
        const churnRate = total > 0 ? (data.casualties / total) * 100 : 0;

        return {
            subByUrl: subByUrl,
            subByPlan: subByPlan.acc,
            singUps: data.signups,
            singRenews: data.singrenews,
            optOuts: data.casualties,
            churnRate: churnRate.toFixed(1),
            singUpsDetails: data?.signups_details,
            singRenewsDetails: data?.singrenews_details,
            optOutsDetails:  data?.casualities_details,
        };
    }

    return null;
}

