import {
  Create,
  SimpleForm,
  TextInput,
  required,
  useTranslate,
  BooleanInput,
  useRecordContext,
  useQueryWithStore,
  AutocompleteInput,
  Loading,
  FormDataConsumer,
} from "react-admin";
import { apiCOD } from "../../../utils/utilsFetchBenefits";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  file: {
    width: "500px",
  },
  switcher: {
    "& p": {
      display: "none",
    },
  },
});

export const Form = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const record = useRecordContext();

  const { data: categories } = useQueryWithStore({
    type: "getList",
    resource: "benefit_categories",
    payload: {
      filter: {},
      sort: { field: "name", order: "ASC" },
      pagination: { page: 1, perPage: 10000 },
    },
  });

  return (
    <div style={{ display: "flex", gap: "16px", width: "100%" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <TextInput
          source='name'
          label='resources.benefit_categories.fields.name'
          validate={[required()]}
        />

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (!("has_parent_category" in formData) && formData?.parent_id) {
              formData.has_parent_category = true;
            }
            if (
              "has_parent_category" in formData &&
              !formData?.has_parent_category
            ) {
              formData.parent_id = null;
            }
            return (
              <>
                <BooleanInput
                  source='has_parent_category'
                  label='resources.benefit_categories.fields.minor'
                  resource='plans'
                  initialValue={false}
                  className={classes.switcher}
                />
                {formData?.has_parent_category &&
                  categories &&
                  (categories ? (
                    <AutocompleteInput
                      source='parent_id'
                      label='resources.benefit_categories.fields.parent_id'
                      choices={categories}
                      optionText='name'
                      optionValue='id'
                    ></AutocompleteInput>
                  ) : (
                    <Loading></Loading>
                  ))}
              </>
            );
          }}
        </FormDataConsumer>
      </div>
    </div>
  );
};

export const BenefitCategoriesCreate = ({ ...props }) => {
  const transform = (values) => {
    let result = { ...values };
    result.company = apiCOD;
    return result;
  };

  return (
    <Create title=' ' redirect='list' transform={transform} {...props}>
      <SimpleForm>
        <Form />
      </SimpleForm>
    </Create>
  );
};
