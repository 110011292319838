import * as React from "react";
import { useCallback } from "react";
import DownloadIcon from "@material-ui/icons/GetApp";
import {
  fetchRelatedRecords,
  useDataProvider,
  useNotify,
  useListContext,
  useResourceContext,
} from "ra-core";
import { Button } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";

const ExportButtonCustom = (props) => {
  const {
    maxResults = 1000,
    onClick,
    label = "Exportar",
    icon = defaultIcon,
    exporter: customExporter,
    sort, // deprecated, to be removed in v4
    ...rest
  } = props;
  const {
    filter,
    filterValues,
    currentSort,
    exporter: exporterFromContext,
    total,
  } = useListContext(props);
  const resource = useResourceContext(props);
  const exporter = customExporter || exporterFromContext;
  const dataProvider = useDataProvider();
  const [loading, setLoading] = React.useState(false);
  const notify = useNotify();
  const handleClick = useCallback(
    (event) => {
      getAllData(event);
    },
    [
      currentSort,
      dataProvider,
      exporter,
      filter,
      filterValues,
      maxResults,
      notify,
      onClick,
      resource,
      sort,
    ]
  );
  const getAllData = async (event) => {
    setLoading(true);
    let all = [];
    let resp = await getData();
    let pageTotal = Math.ceil(resp?.total / maxResults);
    all = all.concat(resp?.data);
    let page = 2;
    while (page <= pageTotal) {
      let resp = await getData(page);
      all = all.concat(resp?.data);
      page++;
    }
    try {
      if (exporter)
        exporter(
          all,
          fetchRelatedRecords(dataProvider),
          dataProvider,
          resource
        );
    } catch (error) {
      console.error(error);
      notify("ra.notification.http_error", { type: "warning" });
    }
    if (typeof onClick === "function") {
      onClick(event);
    }
    setLoading(false);
  };

  const getData = async (page = 1) => {
    const response = await dataProvider.getList(resource, {
      sort: currentSort || sort,
      filter: filter ? { ...filterValues, ...filter } : filterValues,
      pagination: { page: page, perPage: maxResults },
    });
    return response;
  };

  return (
    <Button
      onClick={handleClick}
      disabled={total === 0 || loading}
      variant="outlined"
      color="primary"
      style={{marginLeft: "16px"}}
    >
      {loading ? <CircularProgress size={16} style={{marginRight:"4px"}}/> : icon}
      Exportar
    </Button>
  );
};

const defaultIcon = <DownloadIcon />;

export default ExportButtonCustom;
