import * as React from "react";
import {
  BooleanInput,
  Datagrid,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
  BooleanField,
  downloadCSV,
  useNotify,
} from "react-admin";
import { useTranslate } from "ra-core";
import jsonExport from "jsonexport/dist";
import { cloneElement } from "react";
import { TopToolbar, CreateButton } from "react-admin";
import ExportButtonCustom from "./ExportButtonCustom";
import { checkPermissions } from "../../utils/validations";

const RegisteredUsersFilter = (props) => (
  <Filter {...props}>
    <TextInput source="search" label="ra.action.search" alwaysOn />
    <BooleanInput
      source="contributor"
      label="Contribuidor"
      defaultValue={false}
      alwaysOn
    />
    <BooleanInput source="staff" label="Staff" alwaysOn />
  </Filter>
);

export const Actions = ({
  label = "Nuevo",
  resource,
  filter,
  exporter,
  exportButton = false,
  maxResults = 10000,
  ...props
}) => (
  <TopToolbar>
    {props?.filters && cloneElement(props?.filters, { context: "button" })}
    {exportButton && (
      <ExportButtonCustom
        resource={resource}
        filter={filter}
        exporter={exporter}
        maxResults={maxResults}
      />
    )}
    {/* <CreateButton
      variant="contained"
      size="medium"
      style={{ marginLeft: '8px' }}
      label={label}
      translate={"no"}
    /> */}
  </TopToolbar>
);

export const RegisteredUsersList = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const values = [
    { value: "female", label: "Femenino" },
    { value: "male", label: "Masculino" },
    { value: "non-binary", label: "No binarie" },
    { value: "other", label: "Otro" },
  ];
  const getGender = (val) => {
    return values?.find((i) => i?.value == val)?.label;
  };

  const getAge = (fechaNacimiento) => {
    const hoy = new Date();
    const fechaNac = new Date(fechaNacimiento);
    let edad = hoy.getFullYear() - fechaNac.getFullYear();
    const mes = hoy.getMonth() - fechaNac.getMonth();

    if (mes < 0 || (mes === 0 && hoy.getDate() < fechaNac.getDate())) {
      edad--;
    }

    return edad;
  };
  const interestsOptions = [
    { value: 1, label: "Derechos Humanos", icon: "HumanRightsIcon" },
    { value: 2, label: "Deportes", icon: "SportsIcon" },
    { value: 3, label: "Política Argentina", icon: "PolicyIcon" },
    { value: 4, label: "El Mundo", icon: "WorldIcon" },
    { value: 5, label: "Espectáculos", icon: "ShowIcon" },
    { value: 6, label: "Cultura", icon: "CultureIcon" },
    { value: 7, label: "Literatura", icon: "LiteratureIcon" },
    { value: 8, label: "Género y diversidad", icon: "GenderIcon" },
    { value: 9, label: "Historia", icon: "HistoryIcon" },
    { value: 10, label: "Latinoamérica", icon: "LatinAmericaIcon" },
    { value: 11, label: "Ciencia y tecnología", icon: "CienceIcon" },
  ];
  const getInterests = (values) => {
    try{
      if (Array.isArray(values) && values?.length > 0) {
        const res = values
          .map((valor) => {
            const opcion = interestsOptions.find(
              (option) => option.value === valor
            );
            return opcion ? opcion.label : "";
          })
          .filter((label) => label !== "")
          .join(", ");
        return res;
      }
    }
    catch(error){
      console.log(error);
      return "";
    }
    return "";
  };

  const exporter = (values) => {
    let resp = [];
    if (values && values.length > 0) {
      values.forEach((item) => {
        let obj = {};
        obj.id = item?.id;
        obj.Nombre = item.first_name || item.display_name;
        obj.Apellido = item?.last_name || " ";
        obj.Email = item.email;
        obj["Fecha Nacimiento"] = item?.date_of_birth || " ";
        obj.Edad = item?.date_of_birth ? getAge(item?.date_of_birth) : " ";
        obj.Genero = item?.gender ? getGender(item?.gender) : " ";
        obj.Intereses = item?.interests ? getInterests(item.interests) : " ";
        resp.push(obj);
      });
    }
    if (resp && resp?.length > 0) {
      let respErr = "";
      let respCsv;
      jsonExport(resp, { rowDelimiter: ";" }, function (err, csv) {
        if (err) respErr = err;
        respCsv = csv;
      });
      if (respCsv && respErr === "") {
        downloadCSV(
          respCsv,
          `usuarios_registrados_${new Date().toLocaleDateString()}`
        );
      } else {
        notify("resources.subsribers.errors.export", "warning");
      }
    }
  };

  return (
    <List
      filters={<RegisteredUsersFilter />}
      actions={
        <Actions
          label={`${translate("resources.subscribers.new")}`}
          exportButton={true}
          exporter={exporter}
          maxResults={2000}
        />
      }
      {...props}
      title=" "
      sort={{ field: "name", order: "ASC" }}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="display_name" label="Nombre de usuario" />
        <BooleanField source="staff" />
        <BooleanField source="contributor" label="Contribuidor" />
        <TextField source="email" />
        {checkPermissions(props?.permissions, "edit-registered-users") ? (
          <EditButton />
        ) : (
          ""
        )}
      </Datagrid>
    </List>
  );
};
