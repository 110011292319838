import * as React from "react";
import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  CREATE,
  SaveButton,
  SimpleForm,
  Toolbar,
  required,
  showNotification,
  NumberInput,
  minValue,
  number,
  SelectInput,
  DateInput,
  useGetList,
  FormDataConsumer,

} from "react-admin";
import Button from '@material-ui/core/Button';
import IconCancel from '@material-ui/icons/Cancel';
import { InputAdornment } from "@material-ui/core";
import { Box, Typography } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { Alert } from '@material-ui/lab';
import { convertDateToString } from '../../utils/utilsDateFormat'
import { useTranslate } from "ra-core";

const useStyles = makeStyles({
  form: { padding: 0 },
  button: {
    margin: '10px 24px',
    position: 'relative',
    border: '1px solid #212121',
  },
  iconPaddingStyle: {
    paddingRight: '0.5em',
  },
  toolbar: {
    background: '#fff',
    display: 'flex',
    justifyContent: 'flex-end'
  }
});

const PostQuickCreateToolbar = ({ submitting, onCancel, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Toolbar className={classes.toolbar} {...props}>
      <Button className={classes.button} onClick={onCancel}>
        <IconCancel className={classes.iconPaddingStyle} />
        {translate('ra.action.cancel')}
      </Button>
      <SaveButton />
    </Toolbar>
  )
}

const getArrayPlans = (plans, plansIds) => {
  const events =
    plans ? plansIds.map(id => ({
      data: plans[id],
    }))
      : [];

  return events;
};

const ExpirePlan = ({ planes }) => {
  const [expire, setExpire] = React.useState('');
  const { values } = useFormState();
  const translate = useTranslate();

  React.useEffect(() => {
    if (values.start_date && values.plan_id) {
      const days = planes.find(element => element.data.id === values.plan_id).data.duration;
      if (days) {
        const startDate = new Date(values.start_date + translate("components.locale_utc"));
        startDate.setDate(startDate.getDate() + days);
        setExpire(startDate.toLocaleString(translate("components.locale_language"), {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }))
      }
    }
  }, [values.start_date, values.plan_id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!expire) return null;

  return (
    <p style={{ marginLeft: '12px', border: '1px solid #c3c3c3', padding: '6px 10px', borderRadius: '8px', position: 'relative', top: '-10px' }}>
      <Typography variant="h6" style={{ fontSize: '14px' }}>
        {translate("components.expired")}
      </Typography>
      <span style={{ display: 'block', width: 'max-content', fontSize: '14px' }}>{expire}</span>
    </p>
  );
}

const SubscriptionQuickCreate = ({ onCancel, onSave, record, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { data: plans, ids: plansIds } = useGetList(
    'plans',
    { page: 1, perPage: 100 },
    { field: 'name', order: 'ASC' }
  );
  const planes = getArrayPlans(plans, plansIds);
  const dispatch = useDispatch();
  const submitting = useSelector(state => state.admin.loading > 0);

  const { id, email, first_name,last_name,display_name } = record;
  const handleSave = useCallback(
    values => {
      const record = {
        external_user_id: id,
        email: email,
        first_name: first_name || display_name,
        last_name: last_name || "", 
        plan_id: values.plan_id,
        payment_date: values.payment_date,
        start_date: values.start_date,
        paid_amount: values.paid_amount,
      };

      dispatch({
        type: "QUICK_CREATE",
        payload: { data: record },
        meta: {
          fetch: CREATE,
          resource: "subscriptions",
          onSuccess: {
            callback: ({ payload: { data } }) => onSave(data),
          },
          onFailure: {
            callback: ({ error }) => {
              dispatch(showNotification(error, "error"));
            },
          },
        },
      });
    },
    [dispatch, onSave, id]
  );

  return (
    <SimpleForm
      save={handleSave}
      saving={submitting}
      redirect={false}
      toolbar={
        <PostQuickCreateToolbar onCancel={onCancel} submitting={submitting} />
      }
      classes={{ form: classes.form }}
      {...props}
    >
      {
        planes &&
        <SelectInput source="plan_id"
          choices={planes}
          optionText="data.name"
          optionValue="data.id"
          validate={required()}
        />
      }

      <DateInput
        label="Fecha de pago"
        source="payment_date"
        defaultValue={convertDateToString(new Date())}
        validate={required()}
      />

      <NumberInput
        label="components.amount"
        source="paid_amount"
        min={0}
        validate={[number(), minValue(0)]}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              $
            </InputAdornment>
          ),
        }}
      />

      <Box display="flex">
        <Box flexGrow={1} mr=".5em">
          <DateInput
            source="start_date"
            label="resources.subscribers.fields.expire_date"
            defaultValue={convertDateToString(new Date())}
            validate={required()}
            style={{ width: '256px' }}
          />
        </Box>
        {
          planes &&
          <Box flexGrow={1}>
            <ExpirePlan planes={planes} />
          </Box>
        }
      </Box>

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData, ...rest }) => planes && formData.plan_id && plans[formData.plan_id] && plans[formData.plan_id].include_club &&
          <Alert style={{ marginBottom: '1em', display: 'inline-flex' }} severity="info">
            {translate('resources.plans.info_create_cards')}
          </Alert>
        }
      </FormDataConsumer>
    </SimpleForm>
  );
};

export default SubscriptionQuickCreate;
