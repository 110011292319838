import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
} from "react-admin";
import Abilities from "./abilities";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { ROLE, ROLECLUB } from "./constants";
import medio from "Static/data/medio.json";
import { useTranslate } from "ra-core";
import { ActionSaveDelete } from "../../components/Toolbar/ActionSaveDelete";
import { ActionsBack } from "../../components/Toolbar/ActionsBack";
const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const PanelTitle = ({ record }) => {
  const classes = useStyles();
  const translate = useTranslate();
  if (record) {
    return (
      <Typography className={classes.heading}>
        {translate("components.edit_profile")} ({record.id})
      </Typography>
    );
  }
};

export const RoleEdit = (props) => {
  const [choices, setChoices] = React.useState([]);
  const choices_list = ROLE;
  const translate = useTranslate();
  React.useEffect(() => {
    setChoices(
      choices_list.map((i) => ({
        id: i.id,
        name: `${translate("components." + i?.id)}`,
      }))
    );
  }, []);
  return (
    <Edit title=" " {...props} actions={<ActionsBack />}>
      <SimpleForm
        redirect="list"
        toolbar={
          <ActionSaveDelete
            permissions={props?.permissions}
            permissionDelete="delete-roles"
            permissionSave="edit-roles"
          />
        }
      >
        <PanelTitle {...props} />
        <TextInput source="title" validate={[required()]} />
        <SelectInput source="type" choices={choices} validate={[required()]} />
        <Abilities source="abilities" {...props} />
      </SimpleForm>
    </Edit>
  );
};
