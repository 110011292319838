import * as React from "react";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";

export const PAYMENT_METHOD = {
  1: "Mercado Pago",
  3: "Manual",
  4: "Octavo Piso",
  5: "Factura",
};
const useStyles = makeStyles({
  main: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: -8,
    marginBottom: -8,
  },
  chip: { margin: 4 },
});

const PaymentMethod = ({ record }) => {
  const classes = useStyles();
  if (!record) return null;
  const getPaymentMethod = () => {
    return PAYMENT_METHOD[record.subscriptions[0].payment_method] || "Manual";
  };
  return record?.subscriptions &&
    Array.isArray(record.subscriptions) &&
    record.subscriptions.length > 0 ? (
    <span className={classes.main}>
      {record.subscriptions[0]?.plan ? (
        <Chip
          size="small"
          className={classes.chip}
          label={`${getPaymentMethod()}`}
        />
      ) : null}
    </span>
  ) : null;
};

export default PaymentMethod;
