import { Fragment } from "react";
import ExpandMore from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: theme.spacing(5),
    width: 24,
    height: 24,
    minWidth: 24,
    maxWidth: 24,
    paddingRight: 16,
  },
  iconRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
  animated: {
    "& a": {
      transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    },
    transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
  },
  menuItem: {
    borderLeft: "3px solid #fff",
  },
  sidebarIsOpen: {
    "& a": {
      paddingLeft: "26px",
      "& div": {
        minWidth: "33px",
      },
    },
  },
  sidebarIsClosed: {
    "& a": {
      paddingLeft: theme.spacing(2),
    },
  },
  childMenu: {
    paddingLeft: 26,
  },
  childItem: {
    "& a": {
      paddingLeft: "32px",
    },
    paddingLeft: 24,
  },
  display: {
    display: "flex",
    alignItems: "center",
  },
  subMenu: {
    justifyContent: "space-between",
  },
}));

const SubMenu = ({
  handleToggle,
  sidebarIsOpen,
  isOpen,
  name,
  icon,
  children,
  dense,
  child = false,
}) => {
  const classes = useStyles();

  const header = (
    <MenuItem
      dense={dense}
      button
      onClick={handleToggle}
      className={`${classes.menuItem} ${
        child && sidebarIsOpen ? classes.childMenu : ""
      } ${classes.animated} ${classes.subMenu} ${classes.display}`}
    >
      <Box className={classes.display}>
        <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
        <Typography variant='inherit' color='textSecondary'>
          {name}
        </Typography>
      </Box>
      <ListItemIcon className={classes.iconRight}>
        {isOpen ? (
          <ExpandMore />
        ) : (
          <ExpandLess style={{ transform: "rotate(90deg)" }} />
        )}
      </ListItemIcon>
    </MenuItem>
  );

  return (
    <Fragment>
      {sidebarIsOpen || isOpen ? (
        header
      ) : (
        <Tooltip title={name} placement='right'>
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout='auto' unmountOnExit>
        <List
          dense={dense}
          component='div'
          disablePadding
          className={`
          ${classes.animated}
          ${sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed} ${
            child && sidebarIsOpen ? classes.childItem : ""
          } `}
        >
          {children}
        </List>
      </Collapse>
    </Fragment>
  );
};

export default SubMenu;
