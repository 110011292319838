import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogContent,
  Typography,
  Box,
  IconButton,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  arrayInputContainer: {
    display: "flex",
    flexDirection: "column",
  },
  addButton: {
    marginTop: theme.spacing(1),
  },
}));

const ObjectCreate = ({ data, onChange }) => {
  const classes = useStyles();
  const [item, setItem] = useState();
  const [value, setValue] = useState({});
  const [show, setShow] = useState(false);


  useEffect(() => {
    setValue(data || {});
  }, []);

  const handleItemChange = (prev, val) => {
    let copy = {...value};
    delete copy[`${prev}`];
    copy = {...copy,val:""};
    setValue(copy);
  };

  const handleItemRemove = (prev) => () => {
    let copy = {...value};
    delete copy[`${prev}`];
    setValue(copy);
  };

  useEffect(()=>{
    onChange(value);
  },[value])

  const handleAdd = () =>{
    const newvalue = {...value,[item]:""};
    setValue(newvalue);
    setItem("");
  }

  const handleChange = (val) =>{
    setItem(val);
  }

  return (
    <>
      <Button variant="outlined" color="primary" onClick={() => setShow(true)}>
        Agregar Opciones
      </Button>
      <Dialog open={show} onClose={() => setShow(false)} fullScreen fullWidth>
        <DialogContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography></Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setShow(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <div className={classes.arrayInputContainer}>
            <TextField
              value={item}
              onChange={(e) => handleChange(e.target.value)}
              label={`Agregar key`}
              variant="filled"
              size="small"
            />
            <Button
              variant="outlined"
              color="primary"
              className={classes.addButton}
              onClick={handleAdd}
            >
              Agregar
            </Button>
            {value &&
              Object.keys(value)?.map((item, index) => (
                <Box key={index} display="flex" flexDirection="column">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <TextField
                      value={item}
                      onChange={(e) => handleItemChange(item, e.target.value)}
                      label={`key ${index + 1}`}
                      variant="filled"
                      size="small"
                    />
                    <Button
                      variant="outlined"
                      color="secondary"
                      className={classes.addButton}
                      onClick={handleItemRemove(item)}
                    >
                      <DeleteIcon />
                    </Button>
                  </Box>
                  <Divider style={{ margin: "8px 0 8px 0" }} />
                </Box>
              ))}
            <Box></Box>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ObjectCreate;
