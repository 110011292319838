import { ImageField, Show, SimpleShowLayout, TextField } from "react-admin"



export const BenefitAssetsShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <ImageField source="url" />
                <TextField source="url" label="resources.benefit_assets.fields.url_link" />
                <TextField source="alt" />
            </SimpleShowLayout>
        </Show>
    )
}