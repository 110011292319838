import React, { useState } from "react";
import CardWithIcon from "../../../components/CardWithIcon/CardWithIcon";
import BarChart from "@material-ui/icons/BarChart";
import { useTranslate } from "ra-core";
import PieChartCard from "../../../components/PieChartCard/PieChartCard";
import { Box, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import BenefitsForm from "./BenefitsForm";
import { ShowList } from "./ShowList";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "0 0 0 16px",
  },
  flex: { display: "flex", gap: "16px" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftSpacer: { flex: 1 },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  toolbar: {
    display: "flex",
    alignItems: "flex-start",
    gap: "1em",
    minWidth: "100%",
  },
  centered: { display: "flex", justifyContent: "center" },
};

const useStyles = makeStyles({
  formEmpty: {
    "& div": {
      display: "flex",
      marginRight: "8px",
    },
  },
  formTop: {
    "& div": {
      display: "flex",
      marginRight: "4px",
      maxWidth: "180px",
    },
  },
});

const Spacer = () => <span style={{ width: "1em" }} />;

// ========================================================================
// Componente principal
// ========================================================================
const ViewBenefits = (props) => {
  const translate = useTranslate();
  const [data, setData] = useState(null);
  const [formData, setFormData] = useState({});
  const [serverData, setServerData] = useState([]);
  const classes = useStyles();

  // ========================================================================
  // Component body
  // ========================================================================
  // const transformData = (values) => {
  //   console.log(values);
  //   const replacedItems = Object.keys(values).map((key) => {
  //     if (key == "count") {
  //       return { [key]: Number(data[key]) };
  //     } else {
  //       return { [key]: data[key] };
  //     }
  //   });
  //   console.log(replacedItems);
  //   return replacedItems?.reduce((a, b) => Object.assign({}, a, b));
  // };
  return (
    <>
      {data ? (
        <>
          <div style={styles.header}>
            <BenefitsForm
              data={data}
              jss={classes.form + " " + classes.formTop}
              setData={setData}
              serverdata={serverData}
              setserverdata={setServerData}
              formdata={formData}
              setformdata={setFormData}
            />
            <div></div>
          </div>
          <Grid container spacing={3}>
            <Grid container item xs={5}>
              <Grid item xs={12}>
                <Box marginBottom={2}>
                  <CardWithIcon
                    icon={BarChart}
                    title={"Cantidad de transacciones"}
                    subtitle={data.transactions}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                {data.transactioncategory &&
                  data.transactioncategory.length > 0 && (
                    <PieChartCard
                      title={"Transacciones por categoría"}
                      data={data.transactioncategory}
                    />
                  )}
              </Grid>
            </Grid>
            {/* <Grid item xs={4}>
              <CardWithIcon
                icon={BarChart}
                title={"Usuarios sin usar"}
                subtitle={data.unusedcard.message.split(":")[1]}
              ></CardWithIcon>
            </Grid> */}
            {/* <Grid item xs={4}></Grid>
            <Grid item xs={4}></Grid> */}
            <Grid item xs={4}>
              {data.transactionlist && data.transactionlist.length > 0 && (
                <ShowList
                  data={data?.transactionlist}
                  title="Listado de transacciones"
                  primary="name"
                  secondary="date"
                  secondaryText="Fecha: "
                />
              )}
            </Grid>
            <Grid item xs={3}>
              {data.transactionshop && data.transactionshop.length > 0 && (
                <ShowList
                  data={data?.transactionshop}
                  title="Listado de transacciones por comercio"
                  primary="name"
                  secondary="count"
                />
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <Box textAlign="center" m={1} maxWidth={1200}>
          <BarChart style={{ fontSize: "150px" }} color="disabled" />
          <Typography variant="h6">
            {translate("resources.metrics.validation.empty_message")}
          </Typography>
          <Box style={styles.centered} m={1}>
            <BenefitsForm
              serverdata={serverData}
              data={data}
              setserverdata={setServerData}
              jss={classes.form + " " + classes.formEmpty}
              setData={setData}
              formdata={formData}
              setformdata={setFormData}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default ViewBenefits;
