import * as React from "react";
import { Edit, SimpleForm } from "react-admin";
import { Form } from "./create";
import { ActionsBack } from "../../../components/Toolbar/ActionsBack";
import { ActionSaveDelete } from "../../../components/Toolbar/ActionSaveDelete";

export const LocationsEdit = ({ ...props }) => {
  return (
    <Edit title=" " redirect="list" {...props} actions={<ActionsBack />}>
      <SimpleForm
        toolbar={
          <ActionSaveDelete
            permissions={props?.permissions}
            permissionDelete="delete-locations"
            permissionSave="edit-locations"
          />
        }
      >
        <Form />
      </SimpleForm>
    </Edit>
  );
};
