import { Button, useRefresh } from "react-admin";

import { Fragment, useCallback, useState } from "react";
import LibraryAddOutlined from '@material-ui/icons/LibraryAddOutlined';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import SubscriptionQuickCreate from "./SubscriptionQuickCreate";
import { Typography } from '@material-ui/core';
import { useTranslate } from "ra-core";

export const CreateSubscriptionModal = ({ record }) => {
  const refresh = useRefresh();
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const translate = useTranslate();

  const handleNewClick = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      setShowCreateDialog(true);
    },
    [setShowCreateDialog]
  );

  const handleCloseCreate = useCallback(() => {
    setShowCreateDialog(false);
  }, [setShowCreateDialog]);

  const handleSave = useCallback(
    (event) => {
      setShowCreateDialog(false);
      refresh();
    },
    [setShowCreateDialog, refresh]
  );

  return (
    <Fragment>
      <Button style={{ height: 'fit-content' }} label={translate("ra.action.add")} variant="outlined" color="default" onClick={handleNewClick}>
        <LibraryAddOutlined />
      </Button>
      <Dialog
        fullWidth
        open={showCreateDialog}
        onClose={handleCloseCreate}
        onClick={(e) => e.stopPropagation()}
        aria-label={translate("components.new_subscription")}
      >
        <DialogTitle>
          <Typography variant="h6">
            {translate("components.new_subscription_for")}{record.username || record.display_name}
          </Typography>
          <Typography variant="body1" style={{ fontSize: '14px' }}>
            {translate("components.subscription_helper")}
          </Typography>
          <Typography variant="body1" style={{ fontSize: '14px' }}>
            {translate("components.subscription_helper2")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <SubscriptionQuickCreate
            onCancel={handleCloseCreate}
            onSave={handleSave}
            basePath="/subscriptions"
            resource="subscriptions"
            record={record}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
