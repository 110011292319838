import * as React from "react";
import { List, Datagrid, TextField, FunctionField } from "react-admin";
import { checkPermissions } from "../../../utils/validations";
import { ActionsCreate } from "../../../components/Toolbar/ActionsCreate";

export const BenefitCategoriesList = ({ ...props }) => {
  const canEdit = checkPermissions(props?.permissions, "edit-benefit-categories");
  const canCreate = checkPermissions(props?.permissions, "create-benefit-categories");
  return (
    <List
      {...props}
      title=' '
      bulkActionButtons={false}
      actions={<ActionsCreate createButton={canCreate} />}
    >
      <Datagrid rowClick={() => (canEdit ? "edit" : "")}>
        <TextField source='name' />
        <FunctionField
          render={(record) => `${record.parent?.name || ""}`}
          label='resources.benefit_categories.fields.parent_id'
        />
      </Datagrid>
    </List>
  );
};
