import {
  Create,
  SimpleForm,
  TextInput,
  required,
  maxLength,
  useQueryWithStore,
  Loading,
  useRecordContext,
  AutocompleteInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  FormDataConsumer,
  DateTimeInput,
  AutocompleteArrayInput,
  SelectInput,
} from "react-admin";
import { apiCOD } from "../../../utils/utilsFetchBenefits";
import { useFormState, useField } from "react-final-form";
import { Box, Typography, Divider } from "@material-ui/core";
import { ImageInputBenefit } from "../components/ImageInputBenefit";
import ImagePickerBenefits from "../components/ImagePickerBenefit";
import { makeStyles } from "@material-ui/styles";
import { useEffect } from "react";
import { TooltipComponent } from "../../../components/TooltipComponent/TooltipComponent";
import RichTextInput from "ra-input-rich-text";
const useStyles = makeStyles((theme) => ({
  boxCard: {
    boxSizing: "border-box",
    border: "1px solid #e5e5e5",
    gap: "10px",
    display: "flex",
    flexDirection: "row",
    borderRadius: "4px",
    padding: "16px",
    marginBottom: "16px",
    backgroundColor: "#fff",
    width: "100%",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  mobileFlexBox: { flexDirection: "column", gap: "8px", maxWidth: "550px" },
  [theme.breakpoints.up("md")]: {
    flexCards: {
      display: "flex",
      flexDirection: "row",
      gap: "4px",
    },
    rowGallery: {
      display: "flex",
      flexDirection: "row",
      gap: "8px",
    },
    mobileFlexBox: { maxWidth: "1150px", flexDirection: "column" },
    joinedInputs: { display: "flex", flexDirection: "row", gap: "16px" },
    fullWidthDesktop: { width: "100%" },
  },
  hidden: {
    display: "none",
  },
  columnFlex: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
}));

const LocationSelect = ({ getSource, locations }) => {
  const { input: inputProvince } = useField(getSource("province_id"));
  const { input: inputCity } = useField(getSource("city_id"));
  const { input: inputLocation } = useField(getSource("location_id"));
  const { input: inputPlace } = useField(getSource("place"));

  useEffect(() => {
    if (inputPlace?.value) {
      let item = locations?.find((i) => i?.description == inputPlace?.value);
      if (item) {
        inputProvince.onChange(item?.province_id);
        inputCity.onChange(item?.city_id);
        inputLocation.onChange(item?.id);
      }
    }
  }, [inputPlace?.value]);

  useEffect(() => {
    if (inputLocation?.value) {
      let item = locations?.find((i) => i?.id == inputLocation?.value);
      if (item && item?.description != inputPlace?.value) {
        inputPlace.onChange(item.name);
      }
    }
  }, [inputLocation?.value]);

  return (
    <AutocompleteInput
      choices={locations}
      source={getSource("place")}
      label="resources.workshop.fields.workshopdates.place"
      optionText={(value) =>
        `${value?.description} - ${value?.name} - ${value?.city?.name}`
      }
      optionValue="description"
      fullWidth
    />
  );
};

export const Form = (props) => {
  const { values } = useFormState();
  const record = useRecordContext();
  const { input: input_categories } = useField("categories");
  const { input: input_workshopdates } = useField("workshopdates");
  const { data: provinces } = useQueryWithStore({
    type: "getList",
    resource: "provinces",
    payload: {
      filter: {},
      sort: { field: "name", order: "ASC" },
      pagination: { page: 1, perPage: 10000 },
    },
  });
  useEffect(() => {
    if (!record) return;
    if (record?.categories) {
      const categories = record?.categories?.map((e) => e.id);
      input_categories.onChange(categories);
    }
    if (record?.workshopdates) {
      const newDatesArray = [];
      record?.workshopdates.forEach((e) => {
        newDatesArray.push({
          id: e.id,
          date: e.date,
          link: e.link,
          quotes: e.quotes !== 0 ? e.quotes : null,
          place: e.place,
          is_online: Boolean(e.is_online),
          location_id: e.location_id,
          city_id: e.location?.city_id,
          province_id: e.location?.city?.province_id,
          schedule: e.schedule,
        });
      });
      input_workshopdates.onChange(newDatesArray);
    }
  }, [record]);

  const classes = useStyles();

  const getCities = (value, options) => {
    if (!value) return [];
    const selectedProvince = options?.find((province) => {
      return province?.id == value;
    });
    return selectedProvince ? selectedProvince.cities : [];
  };

  const { data: locations } = useQueryWithStore({
    type: "getList",
    resource: "locations",
    payload: {
      filter: {},
      sort: { field: "name", order: "ASC" },
      pagination: { page: 1, perPage: 10000 },
    },
  });
  const { data: authors } = useQueryWithStore({
    type: "getList",
    resource: "benefit_authors",
    payload: {
      filter: {},
      sort: { field: "name", order: "ASC" },
      pagination: { page: 1, perPage: 10000 },
    },
  });

  const { data: categories } = useQueryWithStore({
    type: "getList",
    resource: "benefit_categories",
    payload: {
      filter: {},
      sort: { field: "name", order: "ASC" },
      pagination: { page: 1, perPage: 10000 },
    },
  });

  const getLocations = (city_id, locations) => {
    if (!locations) return [];
    if (!city_id) return locations;
    let res = locations.filter((e) => e?.city_id == city_id);
    return res;
  };
  return (
    <Box display="flex" flexDirection="column">
      <Box
        className={`${classes.boxCard} ${classes.mobileFlexBox} ${classes.joinedInputs}`}
      >
        <Box className={classes.fullWidthDesktop}>
          <Typography variant="h6">Información del taller</Typography>
          <TooltipComponent
            infoCheck
            fullWidth
            label={"Texto de mayor tamaño e impacto. Se muestra en la portada"}
          >
            <TextInput
              source="name"
              label="resources.workshop.fields.name"
              validate={[required()]}
              fullWidth
            />
          </TooltipComponent>
        </Box>

        <Box className={classes.fullWidthDesktop}>
          <Typography style={{ lineHeight: "32px" }} variant="h6">
            Autor
          </Typography>
          {authors ? (
            <AutocompleteInput
              source="author_id"
              label="resources.workshop.fields.author"
              choices={authors}
              optionText="name"
              optionValue="id"
              fullWidth
              resettable
            ></AutocompleteInput>
          ) : (
            <Loading />
          )}
        </Box>
        <Box className={classes.fullWidthDesktop}>
          <Typography variant="h6">Categoría/s del taller</Typography>
          {categories ? (
            <AutocompleteArrayInput
              source="categories"
              label="resources.benefits.fields.categories"
              choices={categories}
              validate={[required()]}
              multiple
              fullWidth
            />
          ) : null}
        </Box>
      </Box>
      <Box className={`${classes.boxCard} ${classes.mobileFlexBox}`}>
        <Box>
          <SelectInput
            source="status"
            choices={[
              { id: "draft", name: "Borrador" },
              { id: "active", name: "Activo" },
              // { id: "disabled", name: "Deshabilitado" },
              { id: "finished", name: "Finalizado" },
              { id: "cancelled", name: "Cancelado" },
              { id: "soldout", name: "Agotado" },
              { id: "last_tickets", name: "Últimos tickets"}
            ]}
            initialValue={"draft"}
            label="Estado"
          />
        </Box>
        <TooltipComponent
          disableFullWidth
          label={"De estar habilitado, se mostrará en la portada principal"}
        >
          <BooleanInput
            source="show_home"
            label="resources.benefits.fields.show_home"
            resource="benefits"
            initialValue={true}
          />
        </TooltipComponent>
        <TooltipComponent
          disableFullWidth
          label={"Permite que el taller sea mostrado en el carrusel principal"}
        >
          <BooleanInput
            source="is_featured"
            label="resources.benefits.fields.is_featured"
            resource="benefits"
            initialValue={false}
          />
        </TooltipComponent>
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <Box className={!formData?.is_featured ? classes.hidden : ""}>
                <ImageInputBenefit
                  label={"Imagen a mostrar en desktop"}
                  source="desktop_banner"
                />
                <ImageInputBenefit
                  label={"Imagen a mostrar en mobile"}
                  source="mobile_banner"
                />
              </Box>
            );
          }}
        </FormDataConsumer>
      </Box>
      <Box className={`${classes.boxCard} ${classes.mobileFlexBox}`}>
        <Typography variant="h6">Descripción del taller</Typography>
        <Box className={classes.joinedInputs}>
          <TooltipComponent
            infoCheck
            label={"Breve descripción del taller. Se muestra en la portada"}
          >
            <RichTextInput
              label="resources.workshop.fields.short_description"
              source="short_description"
              variant="filled"
              toolbar={[["bold", "italic", "link"]]}
              validate={[required(), maxLength(180)]}
              fullWidth
              multiline
              inputProps={{ maxLength: 180 }}
              className={classes.fullWidthDesktop}
            />
          </TooltipComponent>
          <TooltipComponent
            infoCheck
            label={
              "Texto que se muestra una vez que el usuario desea conocer mejor el taller en cuestión"
            }
          >
            <RichTextInput
              label="resources.workshop.fields.description"
              source="description"
              variant="filled"
              toolbar={[["bold", "italic", "link"]]}
              validate={[required()]}
              fullWidth
              inputProps={{ maxLength: 2000 }}
              className={classes.fullWidthDesktop}
              multiline
            />
          </TooltipComponent>
        </Box>
      </Box>

      <Box
        className={`${classes.boxCard} ${classes.mobileFlexBox} ${classes.flexCards}`}
      >
        <Box style={{ maxWidth: "550px" }}>
          <ImageInputBenefit label="Imagen Principal" />
        </Box>
        <ImageInputBenefit
          source="gallery"
          multiple={true}
          label="Galería de imágenes"
          className={classes.flexCards}
          galleryClasses={classes.rowGallery}
        />
      </Box>
      <ImagePickerBenefits {...props} />
      <TooltipComponent
        label={
          "Cuando haga click en subscribirse redirigirá a la página indicada."
        }
      >
        <TextInput source={"redirect"} label="Redirigir a otra página" />
      </TooltipComponent>
      <TooltipComponent
        label={"En caso de mostrar solamente un popup con descuento"}
      >
        <TextInput source={"code"} label="Código descuento" />
      </TooltipComponent>
      <TooltipComponent
        label={"Descripción que se mostrara junto con el código de descuento."}
      >
        <RichTextInput
          label="Descripción del descuento"
          source="description_code"
          variant="filled"
          toolbar={[["bold", "italic", "link"]]}
          fullWidth
          inputProps={{ maxLength: 2000 }}
          className={classes.fullWidthDesktop}
          multiline
        />
      </TooltipComponent>
      <Divider style={{ margin: "0 0 16px 0" }} />
      <Typography variant="h6">Fecha y locación</Typography>
      <ArrayInput source="workshopdates" label=" ">
        <SimpleFormIterator
          getItemLabel={(index) => ""}
          TransitionProps={{ enter: false, exit: false }}
          disableReordering
        >
          <FormDataConsumer>
            {({ getSource, scopedFormData, ...rest }) => {
              if (typeof scopedFormData === "undefined") {
                scopedFormData = { is_online: false, locations: null };
              }
              return (
                <Box display={"flex"} flexDirection="column">
                  <Box display="flex" style={{ gap: "10px" }}>
                    <Box>
                      <DateTimeInput
                        source={getSource("date")}
                        label="resources.workshop.fields.workshopdates.date"
                      />
                    </Box>
                    <TooltipComponent
                      infoCheck
                      disableFullWidth
                      label={"En caso de tener un stock limitado"}
                    >
                      <NumberInput
                        source={getSource("quotes")}
                        label="resources.workshop.fields.workshopdates.quotes"
                      />
                    </TooltipComponent>
                    {/*
                    <NumberInput
                      source={getSource("taked_quotes")}
                      label='resources.workshop.fields.workshopdates.taked_quotes'
                    /> */}
                  </Box>
                  <TooltipComponent label={"En caso de ser un taller online"}>
                    <TextInput
                      source={getSource("link")}
                      label="resources.workshop.fields.workshopdates.link"
                    />
                  </TooltipComponent>
                  <TooltipComponent label={"link al cronograma del taller"}>
                    <TextInput
                      source={getSource("schedule")}
                      label="resources.workshop.fields.workshopdates.schedule"
                    />
                  </TooltipComponent>
                  <TooltipComponent
                    label={"En caso de tratarse de un taller presencial"}
                  >
                    <LocationSelect
                      getSource={getSource}
                      locations={locations}
                      scopedFormData={scopedFormData}
                    />
                  </TooltipComponent>
                  <Box
                    className={classes.boxCard}
                    style={{ flexDirection: "column", gap: "16px" }}
                  >
                    <Typography variant="h5">Dirección</Typography>
                    <Box display={"flex"} style={{ gap: "16px" }}>
                      <BooleanInput
                        source={getSource("is_online")}
                        label="resources.workshop.fields.online"
                        defaultValue={scopedFormData?.is_online || false}

                      />
                    </Box>
                    <Box display={"flex"} style={{ gap: "16px" }}>
                      <BooleanInput
                        source={getSource("show_special_form")}
                        label="Mostrar formulario para añadir acompañante"
                        defaultValue={scopedFormData?.show_special_form || false}
                      />
                    </Box>
                    {!scopedFormData?.is_online && (
                      <Box display={"flex"} style={{ gap: "40px" }}>
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          style={{ width: "100%" }}
                        >
                          {provinces ? (
                            <AutocompleteInput
                              source={getSource("province_id")}
                              label="resources.workshop.fields.province"
                              choices={provinces}
                              validate={[required()]}
                              optionText="name"
                              optionValue="id"
                              fullWidth
                              multiple
                            />
                          ) : (
                            <Loading />
                          )}
                          {scopedFormData.province_id ? (
                            <AutocompleteInput
                              source={getSource("city_id")}
                              label="resources.workshop.fields.city"
                              choices={getCities(
                                scopedFormData.province_id,
                                provinces
                              )}
                              validate={[required()]}
                              optionText="name"
                              optionValue="id"
                              fullWidth
                              multiple
                            />
                          ) : null}
                          {scopedFormData.province_id &&
                          scopedFormData.city_id ? (
                            <AutocompleteInput
                              choices={getLocations(
                                scopedFormData?.city_id,
                                locations
                              )}
                              source={getSource("location_id")}
                              label="Local"
                              validate={[required()]}
                              optionText="name"
                              optionValue="id"
                              fullWidth
                            />
                          ) : null}
                        </Box>
                      </Box>
                    )}
                  </Box>
                  {/* TODO FILES select */}
                </Box>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </Box>
  );
};

export const WorkshopCreate = ({ ...props }) => {
  const transform = (values) => {
    let copy = { ...values };
    copy.company = apiCOD;
    return copy;
  };
  return (
    <Create title=" " transform={transform} redirect="list" {...props}>
      <SimpleForm>
        <Form {...props} />
      </SimpleForm>
    </Create>
  );
};
