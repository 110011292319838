import * as React from "react";
import { List, Datagrid, TextField } from "react-admin";
import { checkPermissions } from "../../../utils/validations";
import { ActionsCreate } from "../../../components/Toolbar/ActionsCreate";
import medio from "Static/data/medio.json";

export const LocationsList = ({ ...props }) => {
  const canEdit = checkPermissions(props?.permissions, "edit-locations");
  const canCreate = checkPermissions(props?.permissions, "create-locations");

  return (
    <List
      {...props}
      title=" "
      bulkActionButtons={false}
      sort={{ field: "description", order: "ASC" }}
      actions={<ActionsCreate createButton={canCreate} />}
    >
      <Datagrid rowClick={() => (canEdit ? "edit" : "")}>
        <TextField source="description" label="Nombre" />
        <TextField source="name" label="Dirección" />
        <TextField
          source="city.name"
          label={medio?.translations?.city || "resources.cities.fields.name"}
        />
        <TextField
          source="city.province.name"
          label={
            medio?.translations?.province || "resources.cities.fields.parent_id"
          }
        />
      </Datagrid>
    </List>
  );
};
