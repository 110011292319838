import { stringify } from "query-string";
import { httpClient, apiUrl, sendAsFormData } from "../utils/utilsFetch";

const dataProviderApi = {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    let query = {
      sortBy: field,
      sortType: order,
      page: page,
      size: perPage,
      filter: JSON.stringify(params.filter),
    };
  
    if (resource == "registered_users") {
      query = {
          text: params.filter?.search || undefined,
          contributor: params.filter?.contributor || false,
          staff: params.filter?.staff || undefined,
          dateTo: params.filter?.dateTo || undefined,
          dateFrom: params.filter?.dateFrom || undefined,
          skip :(page - 1) * perPage,
          limit : perPage,
      };
  }
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json.data,
      total: json.total,
    }));
  },

  getOne: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`).then(
      ({ json }) => ({
        data: json,
      })
    );
  },
  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json.data }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sortBy: field,
      sortType: order,
      page: page,
      size: perPage,
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json.data,
      total: json.total,
    }));
  },

  update: (resource, params) => {
    if (
      resource === "media" ||
      resource === "newspapers" ||
      resource === "authors" ||
      resource === "journalists" ||
      resource === "subscribers"
    ) {
      return sendAsFormData("PUT", resource, params);
    }

    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  locked: (resource, id) => {
    return httpClient(`${apiUrl}/${resource}/locked/${id}`, {
      method: "PUT",
    }).then(({ json }) => ({ data: json }));
  },

  unlocked: (resource, id) => {
    return httpClient(`${apiUrl}/${resource}/unlocked/${id}`, {
      method: "PUT",
    }).then(({ json }) => ({ data: json }));
  },

  create: (resource, params) => {
    if (
      resource === "media" ||
      resource === "newspapers" ||
      resource === "authors" ||
      resource === "journalists" ||
      resource === "subscribers"
    ) {
      return sendAsFormData("POST", resource, params);
    }

    return httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "DELETE",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
  getAbilities: () => {
    return httpClient(`${apiUrl}/abilities`).then(({ json }) => ({
      data: json.data,
    }));
  },
};
export default dataProviderApi;
