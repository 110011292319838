import React, {
    useState,
    useEffect,
    useCallback,
} from 'react';
import { useVersion, useDataProvider, DateField } from 'react-admin';
import { Typography, Card, CardContent, Box, Stepper, Step, StepLabel, StepContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CreateSubscriptionModal } from "./CreateSubscriptionModal";
import { EditSubscriptionModal } from "./EditSubscriptionModal";
import MoneyIcon from '@material-ui/icons/AttachMoney';
import CalendarIcon from '@material-ui/icons/Today';
import { Fragment } from 'react';
import Chip from '@material-ui/core/Chip';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import { useTranslate } from "ra-core";
import medio from "Static/data/medio.json";
const default_language = medio?.default_language ? `${medio?.default_language}` : "es";
const PAYMENT_METHOD = {
    1: "MP",
    3: "M",
    4: "OP",
    5: "F"
}

const useAsideStyles = makeStyles(theme => ({
    root: {
        width: 400,
    },
}));

const statusSwitch = {
    'active': { color: green[600] },
    'renewed': { color: green[400] },
    'expired': { color: red[600] },
    'cancelled': { color: orange[600] },
}

const Aside = ({ record, basePath }) => {
    const classes = useAsideStyles();
    return (
        <div className={classes.root}>
            {record && <EventList record={record} basePath={basePath} />}
        </div>
    );
};

const useEventStyles = makeStyles({
    stepper: {
        background: 'none',
        border: 'none',
        marginLeft: '0.3em',
    },
});

const EventList = ({ record, basePath }) => {
    const classes = useEventStyles();
    const dataProvider = useDataProvider();
    const [state, setState] = useState({});
    const version = useVersion();
    const translate = useTranslate();

    const fetchSubscriptions = useCallback(async () => {
        const { data: subscriptions } = await dataProvider.getList(
            'subscriptions',
            {
                filter: { external_user_id: record && record.id },
                sort: { field: 'id', order: 'DESC' },
                pagination: { page: 1, perPage: 10000 },
            }
        );
        const subscriptionActive = subscriptions.find(subscription => subscription.status === 'active');
        const subscriptionNonActive = subscriptions.filter(subscription => subscription.id !== subscriptionActive?.id);
        setState(state => ({ ...state, subscriptions: subscriptionNonActive, subscriptionActive }));
    }, [dataProvider]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchSubscriptions();
    }, [version]); // eslint-disable-line

    const getData = (subscription) => {
        const {status, payment_method} = subscription;
        const color = statusSwitch[status]?.color || orange[600];
        return <Chip
            size='small'
            label={`${translate(`resources.subscribers.status.${status}`)} | ${getPaymentMethod(payment_method)}`}
            style={{ backgroundColor: color, color: 'white' }}
        />
    }
    const getPaymentMethod = (payment_method) => {
        return PAYMENT_METHOD[payment_method || 3] || "";
    }

    return (
        <>
            <Box m="0 1em 1em 1em">
                <Card>
                    <CardContent>
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="h6">
                                {translate("components.current_subscribe")}
                                { }
                            </Typography>
                            <CreateSubscriptionModal record={record} />
                        </Box>
                        <Box display="flex" mt={{ xs: 0, lg: '.5em' }}>
                            <Box flexGrow={1}>
                                {state && state.subscriptionActive ? (
                                    <>
                                        <Box display="flex">
                                            <Box flexGrow={1}>
                                                <Box display="flex" mb="1em">
                                                    <Typography variant="h6">
                                                        <strong>{state.subscriptionActive.plan.name}</strong>
                                                    </Typography>
                                                    <Chip size='small'
                                                        label={`${translate(`resources.subscribers.status.active`)} | ${getPaymentMethod(state.subscriptionActive.payment_method)}`}
                                                        style={{ position: 'relative', top: '3px', marginLeft: '8px', backgroundColor: green[600], color: 'white' }}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box display="flex">
                                            <Box flexGrow={1}>
                                                <Box display="flex" mb="1em">
                                                    <Box mr=".5em">
                                                        <MoneyIcon
                                                            fontSize="small"
                                                            color="disabled"
                                                        />
                                                    </Box>
                                                    <Box flexGrow={1}>
                                                        <Typography>
                                                            {translate("components.amount")}

                                                        </Typography>
                                                        {Number(state?.subscriptionActive?.paid_amount || 0).toLocaleString(
                                                            translate("components.locale_language"), {
                                                            style: "currency",
                                                            currency: translate("components.currency"),
                                                            minimumFractionDigits: 2,
                                                        })}
                                                    </Box>

                                                </Box>
                                            </Box>

                                            <Box flexGrow={1}>
                                                <Box display="flex">
                                                    <Box mr=".5em">
                                                        <CalendarIcon
                                                            fontSize="small"
                                                            color="disabled"
                                                        />
                                                    </Box>
                                                    <Box flexGrow={1}>
                                                        <Typography>
                                                            {translate("components.payment_date")}
                                                        </Typography>
                                                        <DateField
                                                            record={state.subscriptionActive}
                                                            source="payment_date"
                                                            options={{ timeZone: 'UTC' }}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box display="flex" mb="1em">
                                            <Box mr=".5em">
                                                <CalendarIcon
                                                    fontSize="small"
                                                    color="disabled"
                                                />
                                            </Box>
                                            <Box flexGrow={1}>
                                                <Typography>
                                                    {translate("components.expired")}
                                                </Typography>
                                                <DateField
                                                    record={state.subscriptionActive}
                                                    source="expire_date"
                                                    options={{ timeZone: 'UTC' }}
                                                />
                                            </Box>
                                        </Box>
                                        <EditSubscriptionModal username={record.username || record?.display_name} record={state.subscriptionActive} />
                                    </>
                                ) : <Typography>{translate("components.no_plan")}</Typography>}
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            {state && state.subscriptions && state.subscriptions.length > 0 &&
                <Fragment>
                    <Box m="0 24px">
                        <Typography variant="h6">
                            {translate("components.previous_subscription")}
                        </Typography>
                    </Box>
                    <Stepper orientation="vertical" classes={{ root: classes.stepper }}>
                        {state.subscriptions.map(subscription => {
                            return (
                                <Step key={`${subscription.id}`}
                                    expanded
                                    active
                                    completed
                                >
                                    <StepLabel
                                        StepIconComponent={() => (
                                            <MoneyIcon
                                                fontSize="small"
                                                color="disabled"
                                                style={{ paddingLeft: 3 }}
                                            />
                                        )}
                                    >
                                        <Typography variant="body2" style={{ fontWeight: 600 }}>
                                            {subscription.plan.name}
                                        </Typography>
                                    </StepLabel>
                                    <StepContent>
                                        <Typography variant="body2" gutterBottom>
                                            <span>{getData(subscription)}</span> -
                                            <span>
                                                {" "}{new Date(subscription.payment_date + " 00:00:00").toLocaleString(translate("components.locale_language"), {
                                                    timeZone: default_language == "es" ? 'America/Argentina/Buenos_Aires' : undefined,
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: 'numeric',
                                                })}
                                            </span>
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {Number(subscription?.paid_amount || 0).toLocaleString(
                                                translate("components.locale_language"), {
                                                style: "currency",
                                                currency: translate("components.currency"),
                                                minimumFractionDigits: 2,
                                            })}
                                        </Typography>
                                    </StepContent>
                                </Step>
                            )
                        })}
                    </Stepper>
                </Fragment>
            }
        </>
    );
};

export default Aside;
