import * as React from "react";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import { makeStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { linkToRecord, useListContext } from "react-admin";
import { Link } from "react-router-dom";
import { checkPermissions } from "../../../utils/validations";

const useStyles = makeStyles((theme) => ({
  gridList: {
    margin: 0,
  },
  tileBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)",
  },
  reverseTileBar: {
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)",
  },
  placeholder: {
    backgroundColor: theme.palette.grey[300],
    height: "100%",
  },
  price: {
    display: "inline",
    fontSize: "1em",
  },
  link: {
    color: "#fff",
  },
  audioImg: {
    objectFit: "none",
  },
}));

const getColsForWidth = (width) => {
  if (width === "xs") return 2;
  if (width === "sm") return 3;
  if (width === "md") return 3;
  if (width === "lg") return 5;
  return 6;
};

const times = (nbChildren) =>
  Array.from({ length: nbChildren }, (_, key) => key);

const LoadingGridList = ({ width, nbItems = 20 }) => {
  const classes = useStyles();
  return (
    <ImageList
      rowHeight={180}
      cols={getColsForWidth(width)}
      className={classes.gridList}
    >
      {" "}
      {times(nbItems, (key) => (
        <ImageListItem key={key}>
          <div className={classes.placeholder} />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

const LoadedGridList = ({ width, permissions }) => {
  const { ids, data, basePath } = useListContext();
  const classes = useStyles();
  const action = checkPermissions(permissions, "edit-benefits")
    ? "edit"
    : "show";
  if (!ids || !data) return null;
  return (
    <ImageList
      rowHeight={180}
      cols={getColsForWidth(width)}
      className={classes.gridList}
    >
      {ids?.map((id, key) => {
        return (
          data[id] && (
            <ImageListItem
              component={Link}
              key={key}
              to={linkToRecord(basePath, data[id].id, action)}
            >
              {data[id]?.featured_image ? (
                <img
                  src={`${data[id]?.featured_image}?${Math.floor(
                    Math.random() * 10
                  )}`}
                  alt='preview'
                />
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: 180,
                    backgroundColor: "white",
                  }}
                />
              )}
              <ImageListItemBar
                className={classes.reverseTileBar}
                title={data[id]?.business?.name || ""}
                actionPosition='left'
                position='top'
              />
              <ImageListItemBar
                className={classes.tileBar}
                title={data[id].name}
                subtitle={<div dangerouslySetInnerHTML={{__html:data[id].short_description}}></div>}
              />
            </ImageListItem>
          )
        );
      })}
    </ImageList>
  );
};

const GridList = ({ width, permissions }) => {
  const { loaded } = useListContext();
  return loaded ? (
    <LoadedGridList width={width} permissions={permissions} />
  ) : (
    <LoadingGridList width={width} />
  );
};

export default withWidth()(GridList);
