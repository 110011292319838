import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
} from "react-admin";
import Abilities from "./abilities";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { ROLE, ROLECLUB } from "./constants";
import medio from "Static/data/medio.json";
import { useTranslate } from "ra-core";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const PanelTitle = ({ record }) => {
  const classes = useStyles();
  const translate = useTranslate();
  if (record) {
    return (
      <Typography className={classes.heading}>{translate("components.new_profile")}</Typography>
    );
  }
};

const slugify = (str) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");

export const RoleCreate = (props) => {
  const transform = (data) => ({
    ...data,
    name: `${slugify(data?.title)}`,
  });
  const translate = useTranslate();
  const [choices, setChoices] = React.useState([]);
  const choices_list = ROLE;
  React.useEffect(() => {
    setChoices(choices_list.map((i) => ({
      id: i.id,
      name: `${translate("components." + i?.id)}`
    })))
  }, [])
  return (
    <Create title=" " {...props} transform={transform} >
      <SimpleForm redirect="list" >
        <PanelTitle {...props} />
        <TextInput source="title" validate={[required()]} />
        <SelectInput
          source="type"
          choices={choices}
          validate={[required()]}
          defaultValue={medio?.only_club ? 'admin' : undefined}
        />
        <Abilities source="abilities" {...props} />
      </SimpleForm>
    </Create>
  );
};
