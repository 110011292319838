import {
  Create,
  SimpleForm,
  TextInput,
  required,
  useRecordContext,
  maxLength,
  useQueryWithStore,
  AutocompleteInput,
  NumberInput,
  FormDataConsumer,
  BooleanInput,
  DateInput,
  AutocompleteArrayInput,
  SelectInput,
  SimpleFormIterator,
  ArrayInput,
} from "react-admin";
import { apiCOD } from "../../../utils/utilsFetchBenefits";
import { useFormState, useField } from "react-final-form";
import ImagePickerBenefits from "../components/ImagePickerBenefit";
import { ImageInputBenefit } from "../components/ImageInputBenefit";
import { TypeBenefit } from "../components/TypeBenefit";
import { useEffect } from "react";
import RenderFields from "../components/RenderFields";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { TooltipComponent } from "../../../components/TooltipComponent/TooltipComponent";
import RichTextInput from "ra-input-rich-text";
import medio from "Static/data/medio.json";


const CHOICES = [
  { name: "Activo", id: "active" },
  { name: "Deshabilitado", id: "disabled" },
  { name: "Pendiente", id: "pending" },
  { name: "Borrador", id: "draft" },
  { name: "Rechazado", id: "rejected" },
];

const useStyles = makeStyles({
  boxCard: {
    boxSizing: "border-box",
    border: "1px solid #e5e5e5",
    gap: "10px",
    display: "flex",
    flexDirection: "row",
    borderRadius: "4px",
    padding: "16px",
    marginBottom: "16px",
    backgroundColor: "#fff",
    width: "100%",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  hidden: {
    display: "none",
  },
  columnFlex: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
});

export const Form = (props) => {
  const { values } = useFormState();
  const record = useRecordContext();
  const classes = useStyles();
  const { input: input_categories } = useField("categories");

  useEffect(() => {
    if (!record) return;
    if (record?.categories) {
      const categories = record?.categories?.map((e) => e.id);
      input_categories.onChange(categories);
    }

  }, [record]);

  const { data: categories } = useQueryWithStore({
    type: "getList",
    resource: "benefit_categories",
    payload: {
      filter: {},
      sort: { field: "name", order: "ASC" },
      pagination: { page: 1, perPage: 10000 },
    },
  });

  const { data: provinces } = useQueryWithStore({
    type: "getList",
    resource: "provinces",
    payload: {
      filter: {},
      sort: { field: "name", order: "ASC" },
      pagination: { page: 1, perPage: 10000 },
    },
  });

  const { data: business } = useQueryWithStore({
    type: "getList",
    resource: "business",
    payload: {
      filter: {},
      sort: { field: "name", order: "ASC" },
      pagination: { page: 1, perPage: 10000 },
    },
  });

  const getCities = (value, options) => {

    if (!value || !Array.isArray(options) || options.length == 0) return [];

    return options?.find(i => i?.id == value)?.cities || [];
  };

  const { data: locations } = useQueryWithStore({
    type: "getList",
    resource: "locations",
    payload: {
      filter: {
        business_id: values?.business_id ? values?.business_id : null,
      },
      sort: { field: "name", order: "ASC" },
      pagination: { page: 1, perPage: 10000 },
    },
  });
  const getLocales = (city, locales) => {
    return locales?.filter((i) => i?.city_id == city) || [];
  }
  return (
    <div style={{ display: "flex", gap: "16px", width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "750px",
        }}
      >
        <Typography variant='h5'>Configuración</Typography>
        <Box display='flex' flexDirection='column' style={{ gap: "10px" }}>
          <TooltipComponent
            infoCheck
            label={"Texto de mayor tamaño e impacto. Se muestra en la portada"}
          >
            <TextInput
              source='name'
              label='resources.benefits.fields.name'
              validate={[required()]}
            />
          </TooltipComponent>
          <TooltipComponent
            infoCheck
            label={"Breve descripción del beneficio. Se muestra en la portada"}
          >
            <RichTextInput
              label='resources.benefits.fields.short_description'
              source='short_description'
              variant='filled'
              toolbar={[["bold", "italic", "link"]]}
              validate={[required(), maxLength(50)]}
              multiline
              inputProps={{ maxLength: 50 }}
            />
          </TooltipComponent>
          <SelectInput
            source='status'
            label='Estado'
            choices={CHOICES}
          ></SelectInput>
        </Box>
        <Box display='flex' style={{ gap: "10px" }}>
          <TypeBenefit />
          {categories ? (
            <AutocompleteArrayInput
              source='categories'
              label='resources.benefits.fields.categories'
              choices={categories}
              validate={[required()]}
              multiple
            />
          ) : null}
        </Box>

        <TooltipComponent
          infoCheck
          label={
            "Texto que se muestra una vez que el usuario desea conocer mejor el beneficio en cuestión"
          }
        >
          <RichTextInput
            label='resources.benefits.fields.description'
            source='description'
            variant='filled'
            toolbar={[["bold", "italic", "link"]]}
            validate={[required()]}
            style={{ minHeight: "100px" }}
            multiline
          />
        </TooltipComponent>
        <Box className={`${classes.boxCard} ${classes.columnFlex}`}>
          <TooltipComponent
            disableFullWidth
            label={"De estar habilitado, se mostrará en la portada principal"}
          >
            <BooleanInput
              source='show_home'
              label='resources.benefits.fields.show_home'
              resource='benefits'
              initialValue={true}
            />
          </TooltipComponent>
          <TooltipComponent
            disableFullWidth
            label={
              "Permite que el beneficio sea mostrado en el carrusel principal"
            }
          >
            <BooleanInput
              source='is_featured'
              label='resources.benefits.fields.is_featured'
              resource='benefits'
              initialValue={false}
            />
          </TooltipComponent>


          <Box className={!values?.is_featured ? classes.hidden : ""}>
            <ImageInputBenefit
              label={"Imagen a mostrar en desktop"}
              source='desktop_banner'
            />
            <ImageInputBenefit
              label={"Imagen a mostrar en mobile"}
              source='mobile_banner'
            />
          </Box>
        </Box>
        <Box
          className={classes.boxCard}
          style={{ flexDirection: "column", gap: "16px" }}
        >
          <Typography variant='h5'>Sucursales</Typography>
          <>
            <Box display={"flex"} style={{ gap: "16px" }}>
              {business ? <AutocompleteInput
                source={'business_id'}
                label='resources.benefits.fields.business'
                choices={business}
                validate={[required()]}

              ></AutocompleteInput> : null}

              <BooleanInput
                source='available_all_places'
                label='resources.benefits.fields.available_all_places'
                resource='benefits'
                initialValue={false}
              />
            </Box>
            {!values?.available_all_places ? <ArrayInput source="locations" label=" ">
              <SimpleFormIterator getItemLabel={(index) => ` `} disableReordering TransitionProps={{ enter: false, exit: false }}>
                <FormDataConsumer>
                  {({ getSource, scopedFormData }) => {
                    return (
                      <div style={{ display: "flex", gap: "10px" }}>
                        {!values?.available_all_places && provinces && values?.business_id ? (
                          <AutocompleteInput
                            source={getSource('province_id')}
                            label={medio?.translations?.province || 'resources.benefits.fields.province'}
                            choices={provinces}
                            validate={[required()]}
                            optionText='name'
                            optionValue='id'
                            multiple
                          />
                        ) :
                          null
                        }
                        {scopedFormData?.province_id ? (
                          <AutocompleteInput
                            source={getSource('city_id')}
                            label={medio?.translations?.city || 'resources.benefits.fields.city'}
                            choices={getCities(scopedFormData.province_id, provinces)}
                            validate={[required()]}
                            optionText='name'
                            optionValue='id'
                            multiple
                          />
                        ) : null}
                        {scopedFormData?.province_id &&
                          scopedFormData?.city_id ?
                          (
                            <AutocompleteInput
                              choices={getLocales(scopedFormData?.city_id, locations)}
                              source={getSource('id')}
                              label='Locales'
                              validate={[required()]}
                              optionText='name'
                              optionValue='id'
                            />
                          ) : null}
                      </div>
                    )
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput> : null}
          </>
        </Box>
        <Box
          className={classes.boxCard}
          style={{ flexDirection: "column", gap: "16px" }}
        >
          <Typography variant='h5'>Duración</Typography>
          <Box
            display={"flex"}
            flexDirection='row'
            style={{ gap: "16px" }}
          >
            <DateInput
              source='date_start'
              label='resources.benefits.fields.date_start'
            />
            <DateInput
              source='date_end'
              label='resources.benefits.fields.date_end'
            />
          </Box>

          <Box maxWidth={250}>
            <TooltipComponent
              infoCheck
              label={"En caso de tener un stock limitado"}
            >
              <BooleanInput
                source='is_limited'
                label='resources.benefits.fields.is_limited'
                resource='benefits'
                initialValue={false}
              />
            </TooltipComponent>

            {values?.is_limited ? (
              <NumberInput
                style={{ maxWidth: "250px" }}
                source='quotes'
                label='resources.benefits.fields.quotes'
                validate={(value) => {
                  if (value <= 0) {
                    return "Debe haber por lo menos un cupo";
                  }
                }}
              />
            ) : (
              <></>
            )}
          </Box>
        </Box>
        <TooltipComponent label='Es el que se muestra en la portada'>
          <ImageInputBenefit />
        </TooltipComponent>
        <ImageInputBenefit source='gallery' multiple={true} label='Galería' />
        <ImagePickerBenefits {...props} />
        <RenderFields source='assets' label='Campos extra' />
      </div>
    </div >
  );
};

export const BenefitsCreate = ({ ...props }) => {
  const transform = (values) => {
    let copy = { ...values };
    copy.company = apiCOD;
    return copy;
  };
  return (
    <Create title=' ' transform={transform} redirect='list' {...props}>
      <SimpleForm>
        <Form {...props} />
      </SimpleForm>
    </Create>
  );
};
