import {
  Create,
  SimpleForm,
  TextInput,
  required,
  useQueryWithStore,
  Loading,
  AutocompleteInput,
} from "react-admin";
import { apiCOD } from "../../../utils/utilsFetchBenefits";
import medio from "Static/data/medio.json";

export const Form = () => {

  const { data: provinces } = useQueryWithStore({
    type: "getList",
    resource: "provinces",
    payload: {
      filter: {},
      sort: { field: "name", order: "ASC" },
      pagination: { page: 1, perPage: 10000 },
    },
  });

  return (
    <div style={{ display: "flex", gap: "16px", width: "100%" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <TextInput
          source='name'
          label={medio?.translations?.city || 'resources.cities.fields.name'}
          validate={[required()]}
        />
        {provinces ? (
          <AutocompleteInput
            source='province_id'
            label={medio?.translations?.province || 'resources.benefits.fields.province'}
            choices={provinces}
            validate={[required()]}
            optionText='name'
            optionValue='id'
          ></AutocompleteInput>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export const CitiesCreate = ({ ...props }) => {
  const transform = (values) => {
    let result = { ...values };
    result.company = apiCOD;
    return result;
  };

  return (
    <Create title=' ' redirect='list' transform={transform} {...props}>
      <SimpleForm>
        <Form />
      </SimpleForm>
    </Create>
  );
};
