import * as React from "react";
import {
  CreateButton,
  List,
  Datagrid,
  TopToolbar,
  TextField,
} from "react-admin";
import { Box } from "@material-ui/core";
import { checkPermissions } from "../../../utils/validations";
import medio from "Static/data/medio.json";

const ListActions = ({ permissions }) => {
  return (
    <TopToolbar>
      <Box display='flex' justifyContent='flex-end' width='100%'>
        <Box>
          {/* <BenefitsFilter context="form" /> */}
          {checkPermissions(permissions, "create-provinces") ? (
            <CreateButton variant='contained' size='medium' />
          ) : null}
        </Box>
      </Box>
    </TopToolbar>
  );
};

export const ProvincesList = ({ ...props }) => {
  const canEdit = checkPermissions(props?.permissions, "edit-provinces");
  return (
    <List
      {...props}
      title=' '
      bulkActionButtons={false}
      actions={<ListActions {...props}/>}
    >
      <Datagrid rowClick={() => (canEdit ? "edit" : "")}>
        <TextField source='name' label={medio?.translations?.province || "resources.cities.fields.name"}/>
      </Datagrid>
    </List>
  );
};
