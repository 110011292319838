import { Edit, SimpleForm } from "react-admin";
import { Form } from "./create";
import { apiCOD } from "../../../utils/utilsFetchBenefits";
import { ActionSaveDelete } from "../../../components/Toolbar/ActionSaveDelete";
import { ActionsBack } from "../../../components/Toolbar/ActionsBack";

export const WorkshopEdit = ({ ...props }) => {
  const transform = (values) => {
    let copy = { ...values };
    copy.company = apiCOD;
    return copy;
  };
  return (
    <Edit title=" " transform={transform} {...props} actions={<ActionsBack />}>
      <SimpleForm
        toolbar={
          <ActionSaveDelete
            permissions={props?.permissions}
            permissionDelete="delete-workshop"
            permissionSave="edit-workshop"
          />
        }
      >
        <Form {...props} />
      </SimpleForm>
    </Edit>
  );
};
