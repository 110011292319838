import * as React from "react";
import {
  DateInput,
  Edit,
  TextInput,
  PasswordInput,
  Toolbar,
  FormWithRedirect,
  required,
  email,
  minLength,
  TextField,
  NumberInput,
  DeleteWithConfirmButton,
  SaveButton,
} from "react-admin";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { validatePasswords } from "./create";
import { AvatarInput } from "./AvatarInput";
import Aside from "./aside";
import { useTranslate } from "ra-core";
import { ActionsBack } from "../../components/Toolbar/ActionsBack";
import { checkPermissions } from "../../utils/validations";

export const MemberEdit = (props) => (
  <Edit
    title=" "
    aside={<Aside />}
    {...props}
    resource="subscribers"
    component="div"
    actions={<ActionsBack />}
  >
    <SubscriberForm {...props} />
  </Edit>
);

const SubscriberForm = ({ permissions, ...props }) => {
  const translate = useTranslate();
  return (
    <FormWithRedirect
      {...props}
      validate={validatePasswords}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: "block", lg: "flex" }}>
                <Box flex={2} mr={{ md: 0, lg: "1em" }}>
                  <Typography variant="h6" gutterBottom>
                    {translate("components.edit_user")} (
                    {formProps.record && formProps.record.id})
                  </Typography>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1} style={{ alignSelf: "center" }}>
                      <AvatarInput
                        resource="subscribers"
                        fullWidth
                        record={formProps.record}
                      />
                    </Box>
                  </Box>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="first_name"
                        resource="subscribers"
                        validate={requiredValidate}
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="last_name"
                        resource="subscribers"
                        validate={requiredValidate}
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1}>
                      <TextInput
                        type="email"
                        source="email"
                        resource="subscribers"
                        validate={[email(), required()]}
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <Box flex={1}>
                    <TextInput
                      type="text"
                      source="external_user_id"
                      label="Id P12"
                      resource="subscribers"
                      validate={[required()]}
                      fullWidth
                    />
                  </Box>
                  {/* <Box display={{ xs: "block", sm: "flex" }}>
                    <DateInput
                      source="birthday"
                      resource="subscribers"
                      style={{ minWidth: "200px" }}
                    />
                  </Box>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <TextInput source="dni" resource="subscribers" />
                  </Box>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="phone_feature"
                        resource="subscribers"
                      />
                    </Box>
                    <Box flex={3}>
                      <TextInput source="phone" resource="subscribers" />
                    </Box>
                  </Box>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <TextInput source="dealer" resource="subscribers" />
                  </Box>
                  <Box mt="1em" />

                  <Typography variant="h6" gutterBottom>
                    {translate("components.address")}
                  </Typography>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="country"
                        resource="subscribers"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="province"
                        resource="subscribers"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                    <Box flex={1}>
                      <TextInput
                        source="city"
                        resource="subscribers"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                  </Box>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="address"
                        resource="subscribers"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <NumberInput
                        source="addr_floor"
                        resource="subscribers"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                    <Box flex={1}>
                      <TextInput
                        source="addr_door"
                        resource="subscribers"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                  </Box>

                  <Box mt="1em" />

                  <Typography variant="h6" gutterBottom>
                    {translate("components.change_password")}
                  </Typography>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <PasswordInput
                        source="password"
                        resource="subscribers"
                        validate={[minLength(6)]}
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <PasswordInput
                        source="password_confirmation"
                        resource="subscribers"
                        validate={[minLength(6)]}
                        fullWidth
                      />
                    </Box>
                  </Box>*/}
                </Box>
                <Box
                  flex={1}
                  ml={{ xs: 0, lg: "1em" }}
                  mt={{ xs: "1em", lg: 0 }}
                >
                  <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                    <Typography variant="h6" gutterBottom>
                      {translate("components.profile")}
                    </Typography>
                    <TextField source="roles.title" fullWidth />
                  </Box>
                </Box>
              </Box>
            </CardContent>
            <Toolbar
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <SaveButton
                handleSubmitWithRedirect={formProps.handleSubmit}
                record={formProps.record}
                basePath={formProps.basePath}
                undoable={true}
                pristine={formProps.pristine}
                invalid={formProps.invalid}
                handleSubmit={formProps.handleSubmit}
                saving={formProps.saving}
                resource="subscribers"
              />
              {checkPermissions(permissions, "delete-subscribers") ? (
                <DeleteWithConfirmButton
                  basePath={formProps.basePath}
                  record={formProps.record}
                  resource="subscribers"
                />
              ) : null}
            </Toolbar>
          </form>
        </Card>
      )}
    />
  );
};

const requiredValidate = [required()];
