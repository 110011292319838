import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogContent,
  Typography,
  Box,
  IconButton,
  Divider
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  arrayInputContainer: {
    display: "flex",
    flexDirection: "column",
  },
  addButton: {
    marginTop: theme.spacing(1),
  },
}));

const ArrayInputSimple = ({ data, onChange }) => {
  const classes = useStyles();
  const [value, setValue] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setValue(data || []);
  }, []);

  const handleAdd = () => {
    const updatedValue = [...value, ""];
    setValue(updatedValue);
    if (onChange) {
      onChange(updatedValue);
    }
  };

  const handleItemChange = (index) => (event) => {
    const updatedValue = value.map((item, i) =>
      i === index ? event.target.value : item
    );
    setValue(updatedValue);
    if (onChange) {
      onChange(updatedValue);
    }
  };

  const handleItemRemove = (index) => () => {
    const updatedValue = value.filter((_, i) => i !== index);
    setValue(updatedValue);
    if (onChange) {
      onChange(updatedValue);
    }
  };

  return (
    <>
      <Button variant="outlined" color="primary" onClick={() => setShow(true)}>
        Agregar Opciones
      </Button>
      <Dialog open={show} onClose={() => setShow(false)} fullScreen fullWidth>
        <DialogContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography></Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setShow(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <div className={classes.arrayInputContainer}>
            {value &&
              value.map((item, index) => (
                <Box key={index} display="flex" flexDirection="column">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <TextField
                      value={item}
                      onChange={handleItemChange(index)}
                      label={`Opción ${index + 1}`}
                      variant="filled"
                      size="small"
                    />
                    <Button
                      variant="outlined"
                      color="secondary"
                      className={classes.addButton}
                      onClick={handleItemRemove(index)}
                    >
                      <DeleteIcon />
                    </Button>
                  </Box>
                  <Divider style={{margin: "8px 0 8px 0"}}/>
                </Box>
              ))}
            <Box>
              <Button
                variant="outlined"
                color="primary"
                className={classes.addButton}
                onClick={handleAdd}
              >
                Agregar
              </Button>
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ArrayInputSimple;
