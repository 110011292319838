import style from "./TooltipComponent.module.css";
export const TooltipComponent = ({
  children,
  label,
  classes = "",
  infoCheck = false,
  position = "left",
  disableFullWidth = false,
}) => {
  return !infoCheck ? (
    <div
      className={` ${style["tooltip-container"]} ${
        disableFullWidth ? style["disabled-full-width"] : ""
      } ${classes} ${style[position]}`}
    >
      {children}
      <span className={style["tooltip-label"]}>{label}</span>
    </div>
  ) : (
    <div
      className={`  ${style["tooltip-container"]} ${
        disableFullWidth ? style["disabled-full-width"] : ""
      } ${style["info-icon-container"]} ${classes}`}
    >
      {children}
      <svg
        className={style["info-icon"]}
        width='24'
        height='24'
        viewBox='0 0 48 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M24 45C35.598 45 45 35.598 45 24C45 12.402 35.598 3 24 3C12.402 3 3 12.402 3 24C3 35.598 12.402 45 24 45Z'
          fill='#2196F3'
        />
        <path d='M22 22H26V33H22V22Z' fill='white' />
        <path
          d='M24 19C25.3807 19 26.5 17.8807 26.5 16.5C26.5 15.1193 25.3807 14 24 14C22.6193 14 21.5 15.1193 21.5 16.5C21.5 17.8807 22.6193 19 24 19Z'
          fill='white'
        />
      </svg>
      <span className={style["tooltip-label"]}>{label}</span>
    </div>
  );
};
